import '@/components/wf-location-listing';
import '@/components/misc/wf-star-rating';
import '@/components/buttons/wf-button';
import '@/components/buttons/wf-button-directions';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import type {PropertyValues} from 'lit';

@customElement('wf-destination-info')
export class DestinationInfo extends LitElement {
  static override styles = [
    typographyStyles,
    flexStyles,
    displayStyles,
    listingStyles,
    css`
      .destination-info {
        border-top: 1px solid var(--acq-panel-fg);
        padding: 10px;
        box-sizing: border-box;
        max-height: calc(
          var(--acq-ui-panel-max-height, 100%) - 3.125rem - 10px - 1px
        );
        overflow-y: auto; /* Add scroll for vertical overflow */
      }

      wf-button-directions {
        margin-bottom: 1rem;
      }

      section {
        color: var(--acq-text-muted-color);
        h2 {
          font-weight: 700;
          margin-bottom: 0.5rem;
        }
        p {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
          a {
            color: inherit;
          }
        }
        ul {
          padding: 0;
          margin: 0;
          gap: 0.625rem;
          li {
            list-style: none;
          }
          li.tag {
            margin-right: 0.625rem;
            margin-top: 0.625rem;
            padding: 0.313rem;
            border-radius: 5px;
            background-color: var(--acq-panel-accent);
          }
        }

        &.photo > img {
          width: 100%;
          aspect-ratio: 4 /3;
          object-fit: cover;
        }

        &:not(:last-child) {
          margin-bottom: 30px;
        }

        &.categories,
        &.tags {
          ul li {
            display: inline-block;
            cursor: pointer;
          }
        }

        /* @link https://stackoverflow.com/a/67068063/2527692 */
        &.description {
          div {
            display: block; /* Fallback for non-webkit */
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
          &.truncated div {
            height: 2.5rem;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        #readMore,
        #readLess {
          cursor: pointer;
          text-decoration: underline;
        }
        #readMore {
          display: none;
        }
        &.truncated {
          #readLess {
            display: none;
          }
          #readMore {
            display: block;
          }
        }

        &.related-destinations {
          wf-location-listing {
            width: 100%;
            &:not(:last-child) {
              border-bottom: 1px solid
                color-mix(in lch, var(--acq-panel-fg) 15%, var(--acq-panel-bg));
            }
          }
        }
      }
    `,
  ];

  @consume({context: wayfinderContext})
  @property({attribute: false})
  public wayfinder!: WF.Wayfinder;

  @property({type: Object})
  public destination!: WF.Destination;

  get #allDestinations(): WF.Destination[] {
    return this.wayfinder.database.destinations.asArray;
  }

  get #destinationCategories(): WF.DestinationCategoryDatabase {
    return this.wayfinder.database.destinationCategories;
  }
  get #destinationCategoriesAndSubCategories(): WF.DestinationCategory[] {
    const categories = this.#destinationCategories.asArray;
    const subCategories = this.#destinationCategories.asArray.flatMap(
      (category) => category.subCategories.asArray
    );
    const uniqueCategories = categories
      .concat(subCategories)
      .reduce((acc, category) => {
        if (!acc.has(category.id)) acc.set(category.id, category);
        return acc;
      }, new Map<number, WF.DestinationCategory>());
    return Array.from(uniqueCategories.values());
  }
  #categoryById(id: number): WF.DestinationCategory | undefined {
    return this.#destinationCategoriesAndSubCategories.find(
      (category) => category.id === id
    );
  }
  #destination = new StoreController(this, destinationStore);
  #previous = new StoreController(this, returnStore);
  #settings = new StoreController(this, settingsStore);
  #search = new StoreController(this, searchStore);
  #nav = new StoreController(this, navigationStore);

  protected override async willUpdate(
    _changedProperties: PropertyValues
  ): Promise<void> {
    super.willUpdate(_changedProperties);

    if (_changedProperties.has('destination')) {
      const {transitionTime} = this.wayfinder.settings;
      // Select the destination
      this.destination.fadeToSelected(transitionTime);
      this.renderRoot.querySelector('.description')?.classList.add('truncated');

      const oldDestination = _changedProperties.get(
        'destination'
      ) as WF.Destination;
      if (oldDestination) {
        // Fade out the old destination
        oldDestination.fadeToDefault(transitionTime);
      }
    }
  }
  override disconnectedCallback(): void {
    super.disconnectedCallback();

    // Fade to deselected state
    const {transitionTime} = this.wayfinder.settings;
    this.destination.fadeToDefault(transitionTime);
  }

  /** Builder to make sure all sections have a similar format */
  #buildSection(
    title: string,
    text: string | null = null,
    content?: ReturnType<typeof html>
  ) {
    return html`
      <section class=${camelCase(title.replaceAll(' ', '-'))}>
        <h3>${title}</h3>
        ${when(text, () => html`<p>${text}</p>`)} ${content}
      </section>
    `;
  }

  get #rating() {
    return this.#buildSection(
      'Rating',
      null,
      html`<wf-star-rating rating=${this.destination.review!}></wf-star-rating>`
    );
  }

  get #destinationPhoto() {
    const image = document.createElement('img');
    image.alt = this.destination.name;
    image.src = this.destination.destination_photo;
    image.decode(); // Preload the image
    return html`<section class="photo">${image}</section>`;
  }

  #categoryClickHandler(category: WF.DestinationCategory) {
    // Select the category
    const {selectCategory, clearDestination} = this.#destination.store;
    selectCategory(category);
    clearDestination();

    const {clearPrevious} = this.#previous.store;
    clearPrevious();

    // Update the navigation
    const {setActive} = this.#nav.store;
    setActive('categories');
  }
  @state()
  private truncateCategories = true;
  get #categories() {
    const {behaviour} = this.#settings.store;
    const categories = this.destination.categories;

    const list = categories.slice(0, behaviour.maxCategoriesTags);
    const extra = categories.slice(behaviour.maxCategoriesTags);

    return this.#buildSection(
      'Categories',
      null,
      html`<ul>
        ${repeat(
          this.truncateCategories ? list : categories,
          (categoryId) => categoryId,
          (categoryId) =>
            when(
              this.#categoryById(categoryId),
              (category) =>
                html`<li
                  class="tag"
                  @click=${() => this.#categoryClickHandler(category)}
                >
                  ${category.name}
                </li>`
            )
        )}
        ${when(
          this.truncateCategories && extra.length,
          () =>
            html`<li
              class="tag"
              @click=${() => (this.truncateCategories = false)}
            >
              +${extra.length} more
            </li>`
        )}
      </ul>`
    );
  }

  #tagClickHandler(tag: string) {
    // Clear the destination and previous
    const {clearDestination} = this.#destination.store;
    const {clearPrevious} = this.#previous.store;
    clearDestination();
    clearPrevious();

    // Set the search term
    const {setSearchValue} = this.#search.store;
    setSearchValue(tag);

    // Update the navigation
    const {setActive} = this.#nav.store;
    setActive('search');
  }
  @state()
  private truncateTags = true;
  get tags() {
    const {behaviour} = this.#settings.store;
    const tags = this.destination.tags;

    const list = tags.slice(0, behaviour.maxCategoriesTags);
    const extra = tags.slice(behaviour.maxCategoriesTags);
    return this.#buildSection(
      'Tags',
      null,
      html`<ul>
        ${map(
          this.truncateTags ? list : tags,
          (tag) =>
            html`<li class="tag" @click=${() => this.#tagClickHandler(tag)}>
              ${tag}
            </li>`
        )}
        ${when(
          this.truncateTags && extra.length,
          () =>
            html`<li class="tag" @click=${() => (this.truncateTags = false)}>
              +${extra.length} more
            </li>`
        )}
      </ul>`
    );
  }

  get #openingHours() {
    // Format the opening hours
    const openingHours = groupDaysWithSameValue(
      this.destination.opening_arr
    ).map(({text, oh, ch}) => {
      // 24 hr logic
      if (oh === '00:00' && ch === '00:00')
        return html`<li>${text}: 24 hours</li>`;
      else return html`<li>${text}: ${oh} - ${ch}</li>`;
    });

    return this.#buildSection(
      'Opening Hours',
      this.destination.opening_txt,
      html`<ul>
        ${openingHours}
      </ul>`
    );
  }

  get #contactDetails() {
    const {enableLinks} = this.#settings.store.behaviour;

    // Build website link
    const website = document.createElement('a');
    website.classList.add('website');
    website.target = '_blank';
    if (enableLinks) {
      website.href = this.destination.website;
      website.innerText = 'Website';
    } else {
      website.innerText = this.destination.website;
    }

    return this.#buildSection(
      'Contact Details',
      null,
      html`<p>${website}</p> `
    );
  }

  get #description() {
    const CUTOFF = 85;
    const {description} = this.destination;
    const classes = {
      description: true,
      truncated: description.length > CUTOFF,
    };
    return html`
      <section class=${classMap(classes)}>
        <h3>Description</h3>
        <div>
          <p>${unsafeHTML(description)}</p>
        </div>
        ${when(
          description.length > CUTOFF,
          () =>
            html`<a id="readMore" @click=${() => this.#toggleFullDescription()}>
                More...
              </a>
              <a id="readLess" @click=${() => this.#toggleFullDescription()}>
                Less...
              </a>`
        )}
      </section>
    `;
  }
  #toggleFullDescription() {
    this.renderRoot
      .querySelector('.description')
      ?.classList.toggle('truncated');
  }

  get #relatedDestinations(): WF.Destination[] {
    return this.destination.related_destinations
      .map(({id}) => {
        const hexId = id.toString(16);
        return this.#allDestinations.find(
          ({id}) => String(id).split('-')[1] === hexId
        );
      })
      .filter(Boolean) as WF.Destination[];
  }

  #relatedDestinationClickHandler(relatedDestination: WF.Destination) {
    // Set the 'previous' destination
    const {setPrevious} = this.#previous.store;
    const {destination, discriminator} = this.#destination.store;
    if (discriminator && destination) setPrevious(discriminator, destination);

    // Select the desired destination
    const {selectDestination} = this.#destination.store;
    selectDestination(relatedDestination, 'destination');
  }

  get #relatedDestinationsHtml() {
    return html`
      <section class="related-destinations">
        <h3>You might be interested in...</h3>
        <div class="flex justify-between flex-wrap">
          ${repeat(
            [...shuffle(this.#relatedDestinations)],
            ({id}) => id,
            (destination) =>
              html`<wf-location-listing
                @click=${() =>
                  this.#relatedDestinationClickHandler(destination)}
                .destination=${destination}
              ></wf-location-listing>`
          )}
        </div>
      </section>
    `;
  }

  protected override render() {
    const {showInfo, setShowInfo} = this.#destination.store;

    const header = html`<wf-location-listing
      .open=${showInfo}
      .destination=${this.destination}
      @click=${() => setShowInfo(!showInfo)}
    ></wf-location-listing>`;

    const content = when(
      showInfo,
      () => html`
        <div class="destination-info">
          <wf-button-directions
            .destination=${this.destination}
          ></wf-button-directions>
          ${when(this.destination?.review, () => this.#rating)}
          ${when(this.destination?.description, () => this.#description)}
          ${when(
            this.destination?.destination_photo,
            () => this.#destinationPhoto
          )}
          ${when(this.destination?.location_description, (text) =>
            this.#buildSection('Location', text)
          )}
          ${when(
            this.destination?.opening_arr?.['mon'],
            () => this.#openingHours
          )}
          ${when(this.destination?.categories?.length, () => this.#categories)}
          ${when(this.destination?.tags?.length, () => this.tags)}
          ${when(this.destination?.website, () => this.#contactDetails)}
          ${when(
            this.destination?.related_destinations?.length,
            () => this.#relatedDestinationsHtml
          )}
        </div>
      `
    );

    return html`${header}${content}`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-destination-info': DestinationInfo;
  }
}
