/**
 * This class is a mixin that provides a property `currentFloorId`
 * that is updated whenever the current floor changes.
 */
export class WithCurrentFloor extends LitElement {
  @consume({context: wayfinderContext})
  @property({attribute: false})
  public wayfinder!: WF.Wayfinder;

  protected get currentFloorId(): WF.Floor['id'] {
    return this.wayfinder.floorId;
  }

  #floorChangeEvent({detail}: WF.WayfinderEventMap['floorchange']) {
    if (detail.status === 'changed') {
      logger.withTag('event').debug('Updating due to floor change', detail);
      this.requestUpdate();
    }
  }

  override connectedCallback(): void {
    super.connectedCallback();
    this.wayfinder.addEventListener('floorchange', (e) =>
      this.#floorChangeEvent(e)
    );
  }
  override disconnectedCallback(): void {
    super.disconnectedCallback();
    this.wayfinder.removeEventListener('floorchange', (e) =>
      this.#floorChangeEvent(e)
    );
  }
}
