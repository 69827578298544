import type {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {faQuestion} from '@fortawesome/pro-solid-svg-icons';
import {BaseButton} from './wf-button';

@customElement('wf-button-icon')
export class IconButton extends BaseButton {
  static override styles = [
    ...super.styles,
    fontAwesomeStyles,
    css`
      /* Sizing */
      :host {
        line-height: 1;
        width: 3.125rem; /* 50px */
        height: 3.125rem; /* 50px */
        padding: 0;
      }

      /* Icon element */
      :host > svg[role='img'] {
        width: 1.25rem; /* 20px */
        height: 1.25rem; /* 20px */
      }

      /* Text element */
      :host > span {
        font-size: 0.5rem; /* 8px */
        font-family: var(
          --acq-font-family-body,
          var(--acq-default-fonts, sans-serif)
        );
        margin-top: 5px;
      }
    `,
  ];

  @property({type: Object})
  icon: IconDefinition;

  @property({type: String})
  text?: string;

  constructor() {
    super();
    // Default icon
    this.icon = faQuestion;
  }

  get #textHTML() {
    return when(this.text, (text: string) => html`<span>${text}</span>`);
  }

  override render() {
    return html`${useIcon(this.icon)} ${this.#textHTML}`;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    'wf-button-icon': IconButton;
  }
}
