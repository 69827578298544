import '@/components/destinations/wf-destination-list-header';
import '@/components/wf-location-listing';
import {WithCurrentFloor} from '@/mixins/CurrentFloor';

@customElement('wf-amenity-listing')
export class AmenityListing extends WithCurrentFloor {
  static override styles = [
    typographyStyles,
    listingStyles,
    css`
      .listing {
        max-height: calc(var(--acq-ui-panel-max-height, 100%) - 50px - 1px);
        overflow-y: auto;
      }
    `,
  ];

  #selected = new StoreController(this, destinationStore);
  #previous = new StoreController(this, returnStore);
  #settings = new StoreController(this, settingsStore);

  get #amenities(): WF.AmenitiesDatabase {
    return this.wayfinder.database.amenities;
  }
  get #amenityTypes(): WF.AmenityTypesDatabase {
    return this.wayfinder.database.amenityTypes;
  }
  #amentiesByType(category: WF.AmenityType): WF.AmenitiesDatabase {
    return this.#amenities.ofType(category.id);
  }

  get #amenitiesByType(): WF.Amenity[] {
    const {category, showDestinations} = this.#selected.store;
    if (!category) return showDestinations ? this.#amenities.asArray : [];
    return this.#amentiesByType(category as WF.AmenityType).asArray;
  }

  #amenityCategory(categoryId: number): WF.AmenityType {
    return this.wayfinder.database.amenityTypes.asMap[categoryId];
  }

  get #filteredAmenitiesByType(): WF.Amenity[] {
    return this.#amenitiesByType.filter(
      ({type}) => this.#amenityTypes.asMap[type].disable_search === 0
    );
  }

  get #sortedAmenitiesByType(): WF.Amenity[] {
    return this.#filteredAmenitiesByType.sort((a, b) => {
      const catA = this.#amenityCategory(a.type);
      const catB = this.#amenityCategory(b.type);
      return cleanSort(catA.name, catB.name);
    });
  }

  // Amenities on the same floor
  get #nearbySortedAmenitiesByType(): WF.Amenity[] {
    const nearby = this.#sortedAmenitiesByType.filter(
      ({floor}) => floor === this.currentFloorId
    );
    // Remove duplicates
    const uniqueNearby = nearby.reduce((acc, result) => {
      if (!acc.has(result.id)) acc.set(result.id, result);
      return acc;
    }, new Map<WF.Amenity['id'], WF.Amenity>());
    return Array.from(uniqueNearby.values());
  }
  // Amenities on other floors
  get #otherSortedAmenitiesByType(): WF.Amenity[] {
    return this.#sortedAmenitiesByType.filter(
      ({floor}) => floor !== this.currentFloorId
    );
  }

  #singleAmenityClickHandler(amenity: WF.Amenity) {
    const category = this.#selected.store.category as WF.DestinationCategory;
    const {setPrevious} = this.#previous.store;
    const {selectDestination} = this.#selected.store;

    selectDestination(amenity, 'amenity');
    setPrevious('amenities', category);

    // Show the floor of the destination
    this.wayfinder.showFloor(amenity.floor);

    // Zoom to amenity
    const {behaviour} = this.#settings.store;
    if (behaviour.zoomToDestination) {
      zoomToAmenity(this.wayfinder, amenity);
    }
  }

  protected override render(): unknown {
    const category = this.#selected.store.category as WF.AmenityType;
    const header = html`<wf-destination-list-header
      .type=${category}
    ></wf-destination-list-header>`;

    const _nearby = this.#nearbySortedAmenitiesByType;
    const nearby = html`<wf-slim-heading
        aria-level="3"
        data-testid="nearby-amenities-header"
      >
        Nearby Amenities
      </wf-slim-heading>
      ${when(
        _nearby.length,
        () =>
          html`<div role="list" aria-label="Nearby Amenities">
            ${repeat(
              _nearby,
              ({id}) => id,
              (amenity) =>
                html`<wf-location-listing
                  role="listitem"
                  .amenity=${amenity}
                  @click=${() => this.#singleAmenityClickHandler(amenity)}
                ></wf-location-listing>`
            )}
          </div>`,
        () => html`<p>No nearby amenities</p>`
      )}`;

    const _other = this.#otherSortedAmenitiesByType;
    const other = when(
      _other.length,
      () =>
        html`<wf-slim-heading
            aria-level="3"
            data-testid="other-amenities-header"
          >
            Other Amenities
          </wf-slim-heading>
          <div role="list" aria-label="Other Amenities">
            ${repeat(
              _other,
              ({id}) => id,
              (amenity) =>
                html`<wf-location-listing
                  .amenity=${amenity}
                  @click=${() => this.#singleAmenityClickHandler(amenity)}
                ></wf-location-listing>`
            )}
          </div>`
    );

    return html`${header}
      <div class="listing">${nearby}${other}</div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-amenity-listing': AmenityListing;
  }
}
