import {Exome} from 'exome';

class SearchStore extends Exome {
  public searchValue?: string;

  public setSearchValue(value?: string) {
    this.searchValue = value;
  }

  public clearSearchValue() {
    this.setSearchValue(undefined);
  }
}

export const searchStore = new SearchStore();
