import {
  faStarHalfStroke as faStarHalf,
  faStar as faStarEmpty,
} from '@fortawesome/pro-regular-svg-icons';
import {faStar as faStarFull} from '@fortawesome/pro-solid-svg-icons';

@customElement('wf-star-rating')
export class StarRating extends LitElement {
  static override styles = [
    fontAwesomeStyles,
    css`
      .review {
        color: #ffc220;
      }
    `,
  ];

  @property({type: String})
  rating: string = '0';

  get #stars(): ReturnType<typeof html> {
    let rating = parseFloat(this.rating);
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (rating >= 1) {
        stars.push(faStarFull);
      } else if (rating >= 0.5) {
        stars.push(faStarHalf);
      } else {
        stars.push(faStarEmpty);
      }
      rating--;
    }
    return html`${map(stars, (i) => useIcon(i))}`;
  }

  protected override render() {
    return html`${this.rating} <span class="review">${this.#stars}</span>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-star-rating': StarRating;
  }
}
