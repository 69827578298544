import '@/components/search/wf-search-results';
import '@/components/inputs/wf-search-box';

@customElement('wf-search-panel')
export class SearchPanel extends LitElement {
  static override styles = [
    typographyStyles,
    displayStyles,
    cardStyles,
    css`
      p {
        color: var(--acq-text-muted-color);

        margin: 0;
        padding: 10px 20px;
      }
      wf-search-box {
        border-bottom: 1px solid
          color-mix(in lch, var(--acq-panel-fg) 15%, var(--acq-panel-bg));
      }
      wf-search-results {
        display: block;
        max-height: var(--acq-ui-panel-max-height, 100%);
        overflow-y: auto;
      }
    `,
  ];

  #previous = new StoreController(this, returnStore);
  #search = new StoreController(this, searchStore);

  #setSearch({detail}: CustomEvent): void {
    const {setSearchValue} = this.#search.store;
    setSearchValue(detail);
  }

  @consume({context: wayfinderContext})
  @property({attribute: false})
  public wayfinder!: WF.Wayfinder;

  override connectedCallback(): void {
    super.connectedCallback();
    // Set the search query if we have returned from a destination
    const {previous, data} = this.#previous.store;
    const {setSearchValue} = this.#search.store;
    if (previous === 'search' && data) setSearchValue(String(data));
  }
  override disconnectedCallback(): void {
    super.disconnectedCallback();
    const {clearSearchValue} = this.#search.store;
    clearSearchValue();
  }

  protected override render() {
    const {searchValue} = this.#search.store;

    return html`<wf-search-box
        class="block"
        ?autofocus=${true}
        @search=${this.#setSearch}
        searchValue=${ifDefined(searchValue)}
      ></wf-search-box>
      <wf-search-results></wf-search-results>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-search-panel': SearchPanel;
  }
}
