const defaultSettings = {
  features: {
    /** Show the floor selector */
    floorSelector: true,
    /** Show the zoom controls */
    zoomControls: true,
    /** Show the location button */
    currentLocation: true,
    /** Show the routing button */
    routing: true,
    /** Show/hide the routing information, such as distance and time.
     * If `routing` is disabled, `routingInfo` is disabled too. */
    routingInfo: true,
    /** Show the search button */
    search: true,
    /** Show the categories button */
    categories: true,
    /** Show the amenities button */
    amenities: true,
    /** Show suggested Destinations / Amenities */
    suggestions: true,
  },
  behaviour: {
    /** Zoom into a destination when selected */
    zoomToDestination: true,
    /** Show categories with 0 destinations */
    showEmptyCategories: false,
    /** Override showing Long or Short floor name */
    floorNamePreference: undefined as 'long' | 'short' | undefined,
    /** Enable hyperlinks. If `false`, links will be rendered as text */
    enableLinks: true,
    /** Duration in minutes used for calculating 'Opening Soon' and 'Closing Soon' */
    soonDuration: 60,
    /** Maximum number of Categories / Tags to show before truncating */
    maxCategoriesTags: 5,
    /** Whether the info box should be collapsed when the user drags the map */
    dragHidesInfo: true,
    /** Whether to zoom into the starting map once it has loaded */
    zoomOnInitialLoad: true,
    /** Option to show distance in meters or yards. */
    distanceUnits: 'meters' as 'meters' | 'yards',
  },
};
export type UISettings = typeof defaultSettings;

import {Exome} from 'exome';
class SettingsStore extends Exome {
  constructor() {
    super();
    this.settings = structuredClone(defaultSettings);
  }

  public settings: UISettings;

  public get features() {
    return this.settings.features;
  }
  public get behaviour() {
    return this.settings.behaviour;
  }

  public updateSettings(newSettings: Partial<UISettings>) {
    this.settings = defu(newSettings, this.settings);
  }

  public resetSettings() {
    this.settings = structuredClone(defaultSettings);
  }
}
export const settingsStore = new SettingsStore();
