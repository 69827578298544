export const colourStyles = css`
  .green {
    color: #27ae60;
  }
  .bg-green {
    background-color: #27ae60;
  }
  .red {
    color: #eb5757;
  }
  .bg-red {
    background-color: #eb5757;
  }
  .blue {
    color: #2d9cdb;
  }
  .bg-blue {
    background-color: #2d9cdb;
  }
  .orange {
    color: #f2994a;
  }
  .bg-orange {
    background-color: #f2994a;
  }
  .grey,
  .gray {
    color: #5a5a5a;
  }
  .bg-grey,
  .bg-gray {
    background-color: #5a5a5a;
  }
`;
