import '@/components/amenities/wf-amenity-types-listing';
import '@/components/amenities/wf-amenity-listing';

@customElement('wf-amenity-types-selector')
export class AmenityTypesSelector extends LitElement {
  #selected = new StoreController(this, destinationStore);

  protected override render() {
    const {showDestinations} = this.#selected.store;
    return when(
      showDestinations,
      () => html`<wf-amenity-listing></wf-amenity-listing>`,
      () =>
        html`<wf-amenity-types-listing
          class="scrollable"
        ></wf-amenity-types-listing>`
    );
  }

  // We don't need a shadow DOM for this simple selector
  protected override createRenderRoot() {
    return this;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-amenity-types-selector': AmenityTypesSelector;
  }
}
