export const toggleSwitchStyles = css`
  .switch {
    /* Layout */
    position: relative;
    display: inline-block;
    width: 60px;
    height: 25px;

    /* Style */
    border: 2px solid var(--acq-panel-fg, black);
    border-radius: 5px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    .slider:before {
      content: '';

      /* Position */
      position: absolute;
      height: 23px;
      width: 29px;
      left: 1px;
      bottom: 1px;

      /* Style */
      outline: solid 1px var(--acq-panel-bg);
      background-color: var(--acq-panel-fg);
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      /* Transition */
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    input:checked + .slider:before {
      border-radius: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      background-color: var(--acq-button-active-bg, rgb(77, 76, 171));
    }
    input:focus + .slider {
      box-shadow: 0 0 1px var(--acq-button-active-bg, rgb(77, 76, 171));
    }
    input:checked + .slider:before {
      transform: translateX(29px);
    }
  }
`;
