import {
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
} from '@fortawesome/pro-solid-svg-icons';

import '@/components/buttons/wf-button-icon';

@customElement('wf-zoom-controls')
export class ZoomControls extends LitElement {
  static override styles = css`
    :host {
      display: flex;
      flex-direction: row;
      border-radius: 5px;
      background: var(--acq-button-bg, black);
    }
    wf-button-icon:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    wf-button-icon:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  `;

  @consume({context: wayfinderContext})
  @property({attribute: false})
  public wayfinder!: WF.Wayfinder;

  #zoomBy(delta: number) {
    logger.debug('Zooming by', delta);
    this.wayfinder.zoomBy(delta);
  }

  override render() {
    return html`
      <wf-button-icon
        title="Zoom in"
        .icon=${faMagnifyingGlassPlus}
        @click="${() => this.#zoomBy(1)}"
      ></wf-button-icon>
      <wf-button-icon
        title="Zoom out"
        .icon=${faMagnifyingGlassMinus}
        @click="${() => this.#zoomBy(-1)}"
      ></wf-button-icon>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-zoom-controls': ZoomControls;
  }
}
