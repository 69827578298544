import {Exome} from 'exome';
class NavigationStore extends Exome {
  public active: NavButton['key'] | null = null;

  public setActive(text: NavButton['key'] | null) {
    this.active = text;
  }

  public clearActive() {
    this.setActive(null);
  }
}

export const navigationStore = new NavigationStore();
