import '@/components/buttons/wf-button';
import {WithCurrentFloor} from '@/mixins/CurrentFloor';
import {faCaretRight} from '@fortawesome/pro-solid-svg-icons';

@customElement('wf-floor-selector')
export class FloorSelector extends WithCurrentFloor {
  static override styles = [
    badgeStyles,
    fontAwesomeStyles,
    css`
      :root,
      :host {
        interpolate-size: allow-keywords;
      }
      :host {
        border-radius: 5px;
        background: var(--acq-button-bg);
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.25);
        display: block;
        overflow: auto;
        max-height: calc(
          var(--acq-ui-panel-max-height, 100%) - 2 *
            (var(--acq-ui-panel-padding, 10px))
        );
        min-width: 75px;
      }

      .hidden {
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
        overflow: hidden;
      }

      wf-button {
        box-sizing: content-box;
        position: relative;
        align-items: flex-start;
        letter-spacing: 0.0345em; /* To correct for bold font increasing width */
        &.current {
          background-color: var(--acq-button-active-bg);
          &.floor {
            font-weight: bold;
            letter-spacing: unset;
          }
        }
      }
    `,
  ];

  @state()
  protected _open = false;

  #settings = new StoreController(this, settingsStore);
  get #longOrShortName(): 'long' | 'short' {
    return this.#settings.store.behaviour.floorNamePreference || isMobile()
      ? 'short'
      : 'long';
  }

  get #currentBuildingId(): WF.Building['id'] {
    return this.#floors.asMap[this.currentFloorId]?.building;
  }

  get #buildings(): WF.BuildingDatabase {
    return this.wayfinder.database.buildings;
  }

  get #floors(): WF.FloorDatabase {
    return this.wayfinder.database.floors;
  }

  get #destinations(): WF.DestinationDatabase {
    return this.wayfinder.database.destinations;
  }

  get #amenities(): WF.AmenitiesDatabase {
    return this.wayfinder.database.amenities;
  }

  #floorsInBuilding(buildingId: WF.Building['id']): WF.FloorDatabase {
    return this.#floors.inBuilding(buildingId);
  }

  /** Collapse the floor selector when the mouse leaves the element */
  #collapse() {
    this._open = false;
    this._activeBuildingId = null;
  }

  override connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('mouseleave', this.#collapse);
    this.role = 'navigation';
    this.ariaLabel = 'Map Floors';
  }
  override disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('mouseleave', this.#collapse);
  }

  #caret(isOpen = false) {
    return useIcon(faCaretRight, {
      transform: {
        rotate: isOpen ? 90 : 0,
      },
    });
  }

  #search = new StoreController(this, searchStore);
  #destination = new StoreController(this, destinationStore);

  /** Returns the number of destinations/amenities/search results on the current floor */
  #getCount(current: WF.Floor['id']): number {
    const {searchValue} = this.#search.store;
    if (searchValue) {
      // Show count of search results on the current floor
      const results = search(this.wayfinder, searchValue);
      return results.filter(({floor}) => floor === current).length;
    }

    const {category, showDestinations, destination, discriminator} =
      this.#destination.store;
    if (category && !destination) {
      if (!category && showDestinations) {
        // Show count of destinations/amenities in selected category on floor
        switch (discriminator) {
          case 'amenity':
            return this.#amenities.asArray.filter(
              ({floor}) => floor === current
            ).length;
          case 'destination':
            return this.#destinations.asArray.filter(
              ({floor}) => floor === current
            ).length;
        }
      } else {
        // Show count of categories/amenity types on floor
        return 'url' in category
          ? this.#amenities
              .ofType(category.id)
              .asArray.filter(({floor}) => floor === current).length
          : this.#destinations
              .byCategoryId(category.id)
              .asArray.filter(({floor}) => floor === current).length;
      }
    } else if (discriminator === 'destination-group' && destination) {
      // Show count of destinations in destination group on floor
      if (Array.isArray(destination))
        return destination.filter(({floor}) => floor === current).length;
      else return destination.floor === current ? 1 : 0;
    }
    return 0;
  }

  /** Handles the click event for a floor button */
  #floorButtonClickHandler(_: MouseEvent, floor: WF.Floor) {
    if (this._open || this._activeBuildingId) {
      logger.debug('Switching to floor', floor.id);
      this.wayfinder.showFloor(floor.id, true);
      zoomToFloor(this.wayfinder, floor.id);
      if (isMobile()) this.#collapse();
    } else {
      this._open = true;
    }
    this._activeBuildingId = null;
  }

  /** Builds the HTML for a single floor button */
  #floorButton(
    floor: WF.Floor,
    nested = false,
    hide = false
  ): ReturnType<typeof html> {
    const isCurrentFloor = this.currentFloorId === floor.id;
    const isOpenOrActiveBuilding = this._open || !!this._activeBuildingId;
    const hidden = hide || (!isOpenOrActiveBuilding && !isCurrentFloor);

    const classes = {
      hidden,
      floor: true,
      nested,
      current: isCurrentFloor && this._open,
    };

    return html`<wf-button
      class="${classMap(classes)}"
      .active="${isCurrentFloor && isOpenOrActiveBuilding}"
      @click="${(e: MouseEvent) => this.#floorButtonClickHandler(e, floor)}"
      data-floor-id="${floor.id}"
    >
      <span>
        ${choose(this.#longOrShortName, [
          ['long', () => floor.name],
          ['short', () => floor.shortname],
        ])}
        ${when(
          this.#getCount(floor.id),
          (count) => html`<span class="badge">${count}</span>`
        )}
      </span>
    </wf-button>`;
  }

  @state()
  protected _activeBuildingId: WF.Building['id'] | null = null;
  /** Handles the click event for a building button */
  #buildingButtonClickHandler(_: MouseEvent, building: WF.Building) {
    this._open = true;
    if (this._activeBuildingId === building.id) {
      this._activeBuildingId = null;
    } else {
      this._activeBuildingId = building.id;
    }
  }

  /** Builds the HTML for a single building button */
  #buildingButton(building: WF.Building): ReturnType<typeof html> {
    const floors = this.#floorsInBuilding(building.id);
    const floorCountSum = floors.asArray.reduce(
      (sum, {id}) => sum + this.#getCount(id),
      0
    );

    const isCurrent = building.id === this.#currentBuildingId;
    const isActive = this._activeBuildingId === building.id;
    const showIf =
      isCurrent ||
      !!this._activeBuildingId ||
      (floors.length > 1 && this._open);

    const classes = {
      current: isCurrent && this._open,
      building: true,
      hidden: !showIf,
    };

    const buildingButton = when(
      floors.length > 1,
      () =>
        html`<wf-button
          class=${classMap(classes)}
          @click="${(e: MouseEvent) =>
            this.#buildingButtonClickHandler(e, building)}"
          data-building-id="${building.id}"
        >
          <div>
            ${this.#caret(isActive)}
            ${choose(this.#longOrShortName, [
              ['long', () => building.name],
              ['short', () => building.shortname],
            ])}
            ${when(
              floorCountSum,
              (count) => html`<span class="badge">${count}</span>`
            )}
          </div>
        </wf-button>`
    );

    const floorButtons = repeat(
      floors.asArray,
      ({id}) => id,
      (floor) =>
        this.#floorButton(
          floor,
          floors.length > 1,
          !isActive && floors.length > 1
        )
    );
    return html`${buildingButton}${floorButtons}`;
  }

  override render(): unknown {
    if (this.#buildings.length > 1)
      return repeat(
        this.#buildings.asArray,
        ({id}) => id,
        (building) => this.#buildingButton(building)
      );
    else if (this.#floors.length > 1)
      return repeat(
        this.#floors.asArray,
        ({id}) => id,
        (floor) => this.#floorButton(floor)
      );
    else return nothing;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-floor-selector': FloorSelector;
  }
}
