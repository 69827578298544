import '@/components/misc/wf-slim-heading';
import '@/components/wf-location-listing';

@customElement('wf-search-suggestions')
export class WfSearchSuggestions extends LitElement {
  static override styles = [listingStyles];

  #settings = new StoreController(this, settingsStore);

  @property({type: Boolean})
  isRouting = false;

  @property({type: String})
  isSearching?: 'to' | 'from';

  get #shouldShowSuggestions() {
    if (!this.#settings.store.features.suggestions) return false;
    return this.isRouting === false || (this.isRouting && !!this.isSearching);
  }

  @state()
  suggestions: SearchResult[] = [];

  override connectedCallback(): void {
    super.connectedCallback();
    if (this.#settings.store.features.suggestions) {
      // Only calculate the suggestions once
      this.suggestions = this.#searchResults;
    }
  }

  @consume({context: wayfinderContext})
  @property({attribute: false})
  public wayfinder!: WF.Wayfinder;

  get #destinations(): WF.DestinationDatabase {
    return this.wayfinder.database.destinations;
  }
  get #amenities(): WF.AmenitiesDatabase {
    return this.wayfinder.database.amenities;
  }

  // Pick some random detinations and amenities
  // TODO: In the future, there should be a better way to pick these
  get #searchResults(): SearchResult[] {
    // Pick two random destinations
    const randomDestination1 =
      this.#destinations.asArray[
        Math.floor(Math.random() * this.#destinations.length)
      ];
    const randomDestination2 =
      this.#destinations.asArray[
        Math.floor(Math.random() * this.#destinations.length)
      ];
    // pick two random amenities
    const randomAmenity1 =
      this.#amenities.asArray[
        Math.floor(Math.random() * this.#amenities.length)
      ];
    const randomAmenity2 =
      this.#amenities.asArray[
        Math.floor(Math.random() * this.#amenities.length)
      ];

    // Return them in a random order
    return [
      Object.assign(randomDestination1, {
        discriminator: 'destination' as const,
      }),
      Object.assign(randomAmenity1, {discriminator: 'amenity' as const}),
      Object.assign(randomDestination2, {
        discriminator: 'destination' as const,
      }),
      Object.assign(randomAmenity2, {discriminator: 'amenity' as const}),
    ];
  }

  #search = new StoreController(this, searchStore);
  #previous = new StoreController(this, returnStore);
  #selected = new StoreController(this, destinationStore);
  #routing = new StoreController(this, routingStore);

  #searchSuggestionClickHandler({discriminator, ...destination}: SearchResult) {
    const {setPrevious} = this.#previous.store;
    setPrevious('search', this.#search.store.searchValue!);

    if (this.isRouting) {
      if (this.isSearching === 'to') {
        this.#routing.store.setTo(destination);
      } else if (this.isSearching === 'from') {
        this.#routing.store.setFrom(destination);
      }
      this.isSearching = undefined;
    } else {
      this.#selected.store.selectDestination(destination, discriminator);
      // Show the floor of the destination
      this.wayfinder.showFloor(destination.floor);
      // Zoom to destination
      const {behaviour} = this.#settings.store;
      if (behaviour.zoomToDestination) {
        if (discriminator === 'destination') {
          zoomToDestination(this.wayfinder, destination as WF.Destination);
        } else {
          zoomToAmenity(this.wayfinder, destination as WF.Amenity);
        }
      }
    }
  }

  protected override render(): unknown {
    return when(
      this.#shouldShowSuggestions,
      () =>
        html`<wf-slim-heading> Suggestions </wf-slim-heading> ${repeat(
            this.suggestions,
            ({id}) => id,
            (result) =>
              choose(result.discriminator, [
                [
                  'destination',
                  () =>
                    html`<wf-location-listing
                      @click=${() => this.#searchSuggestionClickHandler(result)}
                      .destination=${result as WF.Destination}
                    ></wf-location-listing>`,
                ],
                [
                  'amenity',
                  () =>
                    html`<wf-location-listing
                      @click=${() => this.#searchSuggestionClickHandler(result)}
                      .amenity=${result as WF.Amenity}
                    ></wf-location-listing>`,
                ],
              ])
          )}`
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-search-suggestions': WfSearchSuggestions;
  }
}
