import '@/components/destinations/wf-category-single';

@customElement('wf-amenity-types-listing')
export class AmenityTypesListing extends LitElement {
  static override styles = [
    listingStyles,
    css`
      :host {
        /* Layout */
        display: block;
        position: relative;
        /* Scrolling */
        height: 100%;
        overflow-y: auto;
      }
    `,
  ];

  override connectedCallback(): void {
    super.connectedCallback();
    this.role = 'list';
    this.ariaLabel = 'Amenity Types';
  }

  @consume({context: wayfinderContext})
  @property({attribute: false})
  public wayfinder!: WF.Wayfinder;

  #selected = new StoreController(this, destinationStore);
  #settings = new StoreController(this, settingsStore);

  get #amenities(): WF.AmenitiesDatabase {
    return this.wayfinder.database.amenities;
  }

  get #filteredAmenities(): WF.Amenity[] {
    return this.#amenities.asArray.filter(
      ({type}) =>
        this.wayfinder.database.amenityTypes.asMap[type].disable_search === 0
    );
  }

  get #amenityTypes(): WF.AmenityType[] {
    return this.wayfinder.database.amenityTypes.asArray
      .filter(({disable_search}) => disable_search === 0)
      .sort((a, b) => cleanSort(a.name, b.name));
  }

  #amentiesByType(category: WF.AmenityType): WF.AmenitiesDatabase {
    return this.#amenities.ofType(category.id);
  }

  protected override render() {
    const {selectCategory, category} = this.#selected.store;
    const {showEmptyCategories} = this.#settings.store.behaviour;

    const showAll = html`
      <wf-category-single
        category="ALL"
        .count=${this.#filteredAmenities.length}
        @click=${() => selectCategory(category, true)}
        title="All"
      ></wf-category-single>
    `;

    const typesList = repeat(
      this.#amenityTypes,
      ({id}: WF.AmenityType) => id,
      (amenityType: WF.AmenityType) => {
        const count = this.#amentiesByType(amenityType).length;
        return when(
          showEmptyCategories || count > 0,
          () =>
            html`<wf-category-single
              .category=${amenityType}
              .count=${count}
              @click=${() => selectCategory(amenityType)}
              title=${amenityType.name}
            ></wf-category-single>`
        );
      }
    );

    return html`${showAll}${typesList}`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-amenity-types-listing': AmenityTypesListing;
  }
}
