@customElement('wf-slim-heading')
export class SlimHeading extends LitElement {
  static override styles = [
    typographyStyles,
    css`
      :host {
        /* Layout */
        height: 1rem;
        display: block;
        align-content: center;

        /* Typography */
        font-family: var(--acq-font-family-headings);
        text-transform: uppercase;
        font-size: 0.625rem; /* 10px */
        font-weight: 700;
        line-height: normal;

        /* Style */
        color: var(--acq-slim-heading-fg);
        background: var(--acq-slim-heading-bg);
        padding-left: 7px;
      }
    `,
  ];

  override connectedCallback(): void {
    super.connectedCallback();
    this.role = 'heading';
  }

  protected override render() {
    return html`<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-slim-heading': SlimHeading;
  }
}
