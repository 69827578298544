import {faArrowLeft} from '@fortawesome/pro-solid-svg-icons';

import '@/components/misc/wf-slim-heading';

@customElement('wf-destination-list-header')
export class DestinationListHeader extends LitElement {
  static override styles = [
    fontAwesomeStyles,
    typographyStyles,
    flexStyles,
    displayStyles,
    css`
      :host {
        display: block;
        width: 100%;
      }

      header {
        /* Layout */
        height: 3.125rem; /* 50px */

        /* Styles */
        cursor: pointer;
      }

      /* Destination Title */
      h2 {
        font-family: var(--acq-font-family-headings);
        font-size: 1.25rem; /* 20px */
        font-weight: 400;
        margin: auto 0;
      }

      /* Icon */
      .icon {
        align-content: center;
      }
      svg {
        width: 3.125rem; /* 50px */
        height: 3.125rem; /* 50px */
        margin: auto 0;
      }
    `,
  ];

  #selected = new StoreController(this, destinationStore);

  @property({type: Object})
  category: WF.DestinationCategory | null = null;

  @property({type: Object})
  type: WF.AmenityType | null = null;

  get #name(): string {
    if (this.category) return this.category.name;
    else if (this.type) return this.type.name;
    else return 'All';
  }

  @consume({context: wayfinderContext})
  @property({attribute: false})
  public wayfinder!: WF.Wayfinder;

  #backClickHandler() {
    const {selectCategory, clearCategory, category} = this.#selected.store;
    // If the category has a parent, select the parent
    if (category && 'parent_id' in category && category.parent_id) {
      const parent =
        this.wayfinder.database.destinationCategories.asMap[category.parent_id];
      selectCategory(parent);
    } else clearCategory();
  }

  protected override render() {
    return html`
      <header class="flex">
        <span
          role="button"
          class="icon"
          title="Back to parent category"
          @click=${() => this.#backClickHandler()}
        >
          ${useIcon(faArrowLeft)}
        </span>
        <h2 role="heading" aria-level="2">${this.#name}</h2>
      </header>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-destination-list-header': DestinationListHeader;
  }
}
