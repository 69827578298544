import type {
  IconDefinition,
  IconParams,
  Layer,
} from '@fortawesome/fontawesome-svg-core';

/**
 * Composable to get Lit-compatible SVG from an icon definition.
 * @link https://docs.fontawesome.com/apis/javascript/methods#icon-icondefinition-params
 */
export function useIcon(
  i: IconDefinition,
  params: IconParams = {}
): ReturnType<typeof unsafeSVG> {
  return unsafeSVG(icon(i, params).html[0]);
}

/**
 * Composable to get Lit-compatible SVG from a layer.
 */
export function useLayer(layer: Layer): ReturnType<typeof unsafeSVG> {
  return unsafeSVG(layer.html[0]);
}

// TODO: Move to Zoom utils
import type {RouteResponse} from '@/types/wayfinder';
/** Zoom to a Routeline */
export const zoomToRouteLine = async (
  wayfinder: WF.Wayfinder,
  route: RouteResponse
) => {
  const bounds = wayfinder.getFloorBounds(
    route.waypoints[0].floor.id,
    window.Wayfinder.BoundaryTypeEntireRoute
  );
  if (bounds?.valid) {
    const {idleMapAngle, idleMapTilt} = wayfinder.settings;
    await wayfinder.animateToBounds(bounds, idleMapAngle, idleMapTilt, true);
  }
};
