import {Exome} from 'exome';
import {navigationStore} from './navigation';

type CategorySelection = WF.DestinationCategory | WF.AmenityType;
type DestinationSelection = WF.Destination | WF.Amenity | DestinationGroup;
type Discriminator = 'destination' | 'amenity' | 'destination-group';

class DestinationStore extends Exome {
  // Store the chosen category / amenity type
  public category: CategorySelection | null = null;

  public selectCategory(category: CategorySelection | null, all = false) {
    this.showDestinations = all;
    const hasSubcategories = category && 'subCategories' in category;
    if (
      category !== null &&
      (!hasSubcategories ||
        (hasSubcategories && !category.subCategories.length))
    ) {
      this.showDestinations = true;
    }
    this.category = category;
  }
  clearCategory() {
    this.showDestinations = false;
    this.category = null;
  }

  // Store the selected destination
  public showDestinations = false;
  public destination: DestinationSelection | null = null;
  public discriminator: Discriminator | null = null;

  public selectDestination(
    destination: DestinationSelection,
    discriminator: Discriminator
  ) {
    this.destination = destination;
    this.discriminator = discriminator;
    navigationStore.clearActive();
  }
  public clearDestination() {
    this.destination = null;
    this.discriminator = null;
  }

  // Whether to show the info or not
  public showInfo = true;
  public setShowInfo(showInfo: boolean) {
    this.showInfo = showInfo;
  }
}

export const destinationStore = new DestinationStore();
