import {
  BrowserClient,
  defaultStackParser,
  getDefaultIntegrations,
  makeFetchTransport,
  Scope,
} from '@sentry/browser';
import {version} from '../package.json';

// filter integrations that use the global variable
const integrations = getDefaultIntegrations({}).filter((defaultIntegration) => {
  return ![
    'BrowserApiErrors',
    'TryCatch',
    'Breadcrumbs',
    'GlobalHandlers',
  ].includes(defaultIntegration.name);
});

/**
 * Sentry SDK initialization
 * Since we will have multiple Sentry instances, we need to set a scope
 * @link https://docs.sentry.io/platforms/javascript/best-practices/multiple-sentry-instances/
 */
const client = new BrowserClient({
  enabled: import.meta.env.PROD,

  dsn: 'https://a4dd344198e4e17ecc8a3e4aca6f03aa@o1046978.ingest.us.sentry.io/4507219830898688',
  stackParser: defaultStackParser,
  transport: makeFetchTransport,
  integrations,

  release: `wayfinder-web-map-ui@${version}`,

  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost'],
});

const scope = new Scope();
scope.setClient(client);

client.init();
