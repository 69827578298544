import type {RoutingPanel} from '@/components/routing/wf-routing-panel';

export class WithEventHandlers extends LitElement {
  @consume({context: wayfinderContext})
  @property({attribute: false})
  public wayfinder!: WF.Wayfinder;

  constructor() {
    super();
    if (this.localName !== 'wf-map-ui')
      throw new Error(
        "This mixin can only be used with the 'wf-map-ui' element"
      );
  }

  #destination = new StoreController(this, destinationStore);
  #nav = new StoreController(this, navigationStore);
  #routing = new StoreController(this, routingStore);
  #previous = new StoreController(this, returnStore);
  #search = new StoreController(this, searchStore);
  #settings = new StoreController(this, settingsStore);

  /** Clears selected destination / category / amenity / route etc. */
  #clearSelection() {
    this.#previous.store.clearPrevious();
    this.#destination.store.clearDestination();
    this.#destination.store.clearCategory();
    this.#search.store.clearSearchValue();
  }

  protected _errorHandler({detail}: CustomEvent<string>) {
    logger.withTag('event').error(detail);
  }

  /**
   * Select a destination on click
   * We keep track of the position to see if the user is dragging the map.
   * We don't select a destination if the user is dragging the map.
   */
  protected _position: null | {x: number; y: number} = null;
  protected _hoverDestination: null | WF.Destination = null;

  protected _mouseDownHandler({detail}: WF.MouseCustomEvent) {
    if (import.meta.env.DEV)
      logger.withTag('event').verbose('mousedown', detail);
    this._position = detail.screen;
  }

  protected _mouseMoveHandler({
    detail,
  }: WF.MouseCustomEvent | CustomEvent<null>) {
    if (import.meta.env.DEV)
      logger.withTag('event').verbose('mousemove', detail);
    // User has dragged the map
    if (this._position?.x && this._position?.y) {
      // Hide the info box (if setting is enabled)
      if (this.#settings.store.behaviour.dragHidesInfo)
        this.#destination.store.setShowInfo(false);
    }

    // If cursor has moved to a different destination
    if (detail !== null && detail.destination !== this._hoverDestination) {
      const transitionTime = this.wayfinder?.settings.transitionTime ?? 250;
      // De-select the previous destination
      if (
        this._hoverDestination &&
        this._hoverDestination !== this.#destination.store.destination
      ) {
        this._hoverDestination.fadeToDefault(transitionTime);
      }
      // Select the new destination
      this._hoverDestination = detail.destination;
      if (this._hoverDestination) {
        this._hoverDestination.fadeToHover(transitionTime);
      }
    }
  }

  protected _mouseUpHandler(event: WF.MouseCustomEvent) {
    if (import.meta.env.DEV)
      logger.withTag('event').verbose('mouseup', event.detail);
    const {x, y} = event.detail.screen;
    if (this._position?.x === x && this._position?.y === y) {
      if (event.detail.transitPopup) {
        // Clicked on a Floor Change Callout
        logger.debug('Mouseup', 'Follow transit popup', event.detail);
        event.detail.transitPopup.follow();
      } else if (this.#nav.store.active === 'routing') {
        // Routing panel is open, change the routing target
        this._clickHandleRouting(event);
      } else {
        // We are browsing the map, handle interactions
        this._clickHandleBrowsing(event, this.wayfinder);
      }
    }
    this._position = null;
  }

  /** Click Handler - Actions when routing */
  _clickHandleRouting({detail}: WF.MouseCustomEvent) {
    const target = detail.destination || detail.amenity;
    if (target) {
      const routingPanel =
        this.shadowRoot?.querySelector<RoutingPanel>('wf-routing-panel');
      if (!routingPanel) return;

      // Change routing target
      switch (routingPanel.isSearching) {
        case 'to':
          logger.debug('Mouseup', 'Routing: Setting "To"', detail);
          this.#routing.store.setTo(target);
          break;
        case 'from':
          logger.debug('Mouseup', 'Routing: Setting "From"', detail);
          this.#routing.store.setFrom(target);
          break;
        default:
        // Neither 'To' or 'From' is selected, what should we do?
      }
    } else if (detail.floor) {
      logger.debug('Mouseup', 'Routing: Switching floor', detail);
      this.wayfinder.showFloor(detail.floor);
    } else {
      // Click off the routing panel should clear the screen
      logger.debug('Mouseup', 'Routing: No target selected', detail);
      this.#clearSelection();
      this.#nav.store.clearActive();
      return;
    }
  }

  /** Click Handler - Actions when not routing */
  _clickHandleBrowsing({detail}: WF.MouseCustomEvent, wf: WF.Wayfinder) {
    const {destination, selectDestination} = this.#destination.store;

    // If the currently selected destination/amenity is clicked, do nothing
    if (
      destination !== null &&
      (detail.destination ?? detail.amenity) === destination
    ) {
      logger.debug('Mouseup', 'Target is already selected', detail);
      return;
    }

    this.#clearSelection();
    if (detail.destination) {
      // Select a destination
      logger.debug('Mouseup', 'Selecting destination', detail);
      selectDestination(detail.destination, 'destination');
    } else if (detail.amenity) {
      // Select an amenity
      logger.debug('Mouseup', 'Selecting amenity', detail);
      selectDestination(detail.amenity, 'amenity');
    } else if (detail.floor) {
      // Switch to floor
      logger.debug('Mouseup', 'Selecting floor', detail);
      wf.showFloor(detail.floor);
    }

    this.#nav.store.clearActive();
    this.#routing.store.clear();
  }

  async _mapAppHandler(
    {detail}: WF.WayfinderEventMap['mapapp'],
    wf: WF.Wayfinder
  ) {
    logger.withTag('event').log('Handling mapapp params', detail);

    // We assume all the values have been correctly formatted by the API
    const {unitId, startNodeId, endNodeId, ddaMode, destination, amenity} =
      detail;
    const floorId = wf.database.nodes.fromId(startNodeId)?.floor_id;
    // NOTE: The API should switch floors for us

    // If we have a start and end, initiate a route
    if (startNodeId && endNodeId) {
      // Populate the routing panel
      routingStore.setStepFree(ddaMode);
      routingStore.setFrom({
        id: String(unitId),
        floor: floorId!,
        node: startNodeId,
      });
      routingStore.setTo(
        destination ??
          amenity ?? {
            id: String(unitId),
            floor: floorId!,
            node: [endNodeId],
          }
      );
      navigationStore.setActive('routing');
    } else {
      // Just show the current node
      if (destination || amenity) destinationStore.setShowInfo(true);
      if (destination) {
        destinationStore.selectDestination(destination, 'destination');
        await zoomToDestination(wf, destination);
      } else if (amenity) {
        destinationStore.selectDestination(amenity, 'amenity');
        await zoomToAmenity(wf, amenity);
      } else {
        // Pan to the Unit
        const startNode = wf.database.nodes.fromId(startNodeId);
        if (startNode) await zoomToPosition(wf, startNode.position);
      }
    }
  }
}
