import '@/components/buttons/wf-button-icon';

import {
  faCircleInfo,
  faDiamondTurnRight,
  faLocationDot,
} from '@fortawesome/pro-solid-svg-icons';

@customElement('wf-destination-single')
export class DestinationSingle extends LitElement {
  static override styles = [
    typographyStyles,
    flexStyles,
    displayStyles,
    colourStyles,
    css`
      :host {
        display: flex;
        flex-direction: row;
        padding: 0.313rem 0.625rem; /* 5px 10px */
        gap: 0.625rem; /* 10px */
        height: 3.125rem; /* 50px */
        align-items: center;
      }

      /* Destination Title */
      h2 {
        max-width: 15ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      /* Destination info */
      span {
        color: var(--acq-text-muted-color, #5a5a5a);
        font-size: 0.75rem; /* 12px */
        font-weight: 300;
        line-height: 120%;
      }

      .icon {
        /* Style */
        background: none;
        border: none;

        /* Layout */
        width: 3.125rem; /* 50px */
        height: 3.125rem; /* 50px */

        /* Icon within */
        svg {
          width: 20px;
          height: 20px;
        }
      }

      /* Image or placeholder */
      .logo {
        width: 30px;
        height: 30px;
        object-fit: contain;
        cursor: unset;
      }

      .info {
        flex-grow: 1;
        line-height: 1rem; /* 16px */
      }

      wf-button-icon {
        --acq-button-fg: var(--acq-panel-fg);
        --acq-button-bg: var(--acq-panel-bg);
      }
    `,
  ];

  @consume({context: wayfinderContext})
  @property({attribute: false})
  public wayfinder!: WF.Wayfinder;

  @property({type: Object})
  destination: WF.Destination | null = null;

  @property({type: Object})
  amenity?: WF.Amenity;

  @property({type: Boolean, attribute: 'info'})
  info?: boolean;

  @property({type: Boolean})
  showInfoAction = true;
  @property({type: Boolean})
  showRouteAction = true;

  @property({type: Boolean})
  isRouting = false;
  @property({type: String})
  isSearching?: 'to' | 'from';

  get #multipleFloors(): boolean {
    return this.wayfinder.database.floors.length > 1;
  }

  get #status(): ReturnType<typeof html> | null {
    let color: string | undefined, text: string | undefined;

    // If project has valid timezone and opening hours
    const timezone = this.wayfinder.settings.timezone;
    if (
      validTimezone(timezone) &&
      validOpeningHours(this.destination?.opening_arr)
    ) {
      const status = calculateStatus(
        timezone,
        this.destination.opening_arr,
        this.#settings.store.settings
      );
      color = status.color;
      text = status.text;
    }

    // Explicit status - highest priority
    switch (this.destination?.status) {
      case 'COMING_SOON':
        color = 'blue';
        text = 'Coming Soon';
        break;
      case 'NEW':
        color = 'blue';
        text = 'New';
        break;
      case 'TEMPORARILY_CLOSED':
        color = 'red';
        text = 'Temporarily Closed';
        break;
    }

    return text ? html`<span class="${ifDefined(color)}">${text}</span>` : null;
  }
  get #location(): string | undefined {
    if (!this.#multipleFloors) return;

    // TODO: Handle multiple buildings
    const id = this.amenity?.floor ?? this.destination?.floor;
    if (!id) return;
    const floorDb = this.wayfinder.database.floors;
    const floor = floorDb.asMap[id];

    if (this.amenity) {
      const description = this.amenity.location_description
        ? `| ${this.amenity.location_description}`
        : '';
      return `${floor.name}${description}`;
    } else if (this.destination) {
      return this.destination.floor_name ?? floor.name;
    } else return '';
  }

  get #logo(): string {
    if (this.amenity) {
      const amenityTypes = this.wayfinder.database.amenityTypes;
      return amenityTypes.asMap[this.amenity.type]?.url;
    } else if (this.destination) {
      return this.destination.logo;
    } else return '';
  }

  get #name(): string {
    if (this.amenity) {
      const amenityTypes = this.wayfinder.database.amenityTypes;
      return amenityTypes.asMap[this.amenity.type]?.name;
    } else if (this.destination) {
      return this.destination.name;
    } else return '';
  }

  #selected = new StoreController(this, destinationStore);
  #nav = new StoreController(this, navigationStore);
  #routing = new StoreController(this, routingStore);
  #settings = new StoreController(this, settingsStore);
  #search = new StoreController(this, searchStore);

  #selectDestination(show: boolean) {
    if (this.isRouting) {
      // Set the 'to' or 'from' destination
      switch (this.isSearching) {
        case 'from':
          this.#routing.store.setFrom(this.amenity ?? this.destination!);
          break;
        case 'to':
          this.#routing.store.setTo(this.amenity ?? this.destination!);
          break;
      }
    } else {
      // Select destination
      const {behaviour} = this.#settings.store;
      const {selectDestination, setShowInfo} = this.#selected.store;
      const {clearActive} = this.#nav.store;

      // Will be either an amenity or destination
      const destination = this.amenity ?? this.destination!;

      // Select in the UI
      setShowInfo(show);
      selectDestination(destination, this.amenity ? 'amenity' : 'destination');

      // Show the floor of the destination
      this.wayfinder.showFloor(destination.floor);

      // Zoom to destination
      if (behaviour.zoomToDestination) {
        if (this.amenity) {
          zoomToAmenity(this.wayfinder, this.amenity);
        } else {
          zoomToDestination(this.wayfinder, this.destination!);
        }
      }

      clearActive();
    }
  }

  override connectedCallback(): void {
    super.connectedCallback();
    if (this.info == undefined) {
      // Add click listener when in listing view and not routing
      this.addEventListener('click', () => this.#selectDestination(false));
    }
  }
  override disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('click', () => this.#selectDestination(false));
  }

  // When the (i) button is clicked
  #infoClickHandler(e: Event) {
    e.stopPropagation();
    this.dispatchEvent(
      new CustomEvent('info-click', {bubbles: true, composed: true})
    );

    // Update the UI
    if (this.info !== undefined) {
      // In info view
      this.dispatchEvent(
        new CustomEvent('toggle-info', {bubbles: true, composed: true})
      );
    } else {
      // In listing view
      this.#selectDestination(true);
    }
  }

  // When the <> button is clicked
  #routeClickHandler(e: Event) {
    e.stopPropagation();
    this.dispatchEvent(
      new CustomEvent('route-click', {bubbles: true, composed: true})
    );
    const {setActive} = this.#nav.store;
    const {setTo} = this.#routing.store;
    const {clearDestination, clearCategory} = this.#selected.store;
    const {clearSearchValue} = this.#search.store;

    setTo(this.amenity ?? this.destination!);
    setActive('routing');
    clearDestination();
    clearCategory();
    clearSearchValue();
  }

  protected override render() {
    const {features} = this.#settings.store;

    return html`
      ${when(
        this.#logo,
        () => html`<img class="logo" src=${this.#logo} alt=${this.#name} /> `,
        () =>
          html`<span class="logo icon flex justify-center align-center">
            ${useIcon(faLocationDot)}
          </span>`
      )}
      <div class="info">
        <h2>${this.#name}</h2>
        ${when(this.#location, (location) => html`<span>${location}</span>`)}
        ${when(this.#location && this.#status, () => html`<span> | </span>`)}
        ${when(this.#status, (status) => status)}
      </div>
      <div class="flex">
        ${when(
          this.showInfoAction,
          () =>
            html`<wf-button-icon
              data-testid="info-button"
              ?active=${this.info}
              style=${this.info ? '--acq-button-fg: white;' : ''}
              .icon=${faCircleInfo}
              @click=${this.#infoClickHandler}
            ></wf-button-icon>`
        )}
        ${when(
          this.showRouteAction && features.routing,
          () =>
            html`<wf-button-icon
              .icon=${faDiamondTurnRight}
              @click=${this.#routeClickHandler}
              data-testid="route-button"
            ></wf-button-icon>`
        )}
      </div>
    `;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    'wf-destination-single': DestinationSingle;
  }
}
