import '@/components/destinations/wf-category-single';
import '@/components/destinations/wf-destination-single';

@customElement('wf-category-listing')
export class CategoryListing extends LitElement {
  static override styles = [
    listingStyles,
    css`
      :host {
        /* Layout */
        display: block;
        position: relative;
        /* Scrolling */
        height: 100%;
        overflow-y: auto;
      }
    `,
  ];

  @consume({context: wayfinderContext})
  @property({attribute: false})
  public wayfinder!: WF.Wayfinder;

  #selected = new StoreController(this, destinationStore);
  #settings = new StoreController(this, settingsStore);

  get #destinations(): WF.DestinationDatabase {
    return this.wayfinder.database.destinations;
  }

  get #destinationCategories(): WF.DestinationCategory[] {
    const {category} = this.#selected.store;
    if (!category) {
      return this.wayfinder.database.destinationCategories.asArray;
    } else if (
      'subCategories' in category &&
      category.subCategories.length > 0
    ) {
      return category.subCategories.asArray;
    }

    return this.wayfinder.database.destinationCategories.asArray;
  }

  get #sortedDestinationCategories() {
    return this.#destinationCategories.sort((a, b) =>
      cleanSort(a.name, b.name)
    );
  }

  #destinationsInCategory(categoryId: number): WF.Destination[] {
    return this.#destinations.byCategoryId(categoryId).asArray;
  }

  protected override render() {
    const {selectCategory, category} = this.#selected.store;
    const {showEmptyCategories} = this.#settings.store.behaviour;

    const showAll = html`<wf-category-single
      category="ALL"
      .count=${this.#destinations.length}
      @click=${() => selectCategory(category, true)}
    ></wf-category-single>`;
    const categoriesList = repeat(
      this.#sortedDestinationCategories,
      ({id}) => id,
      (category) => {
        const destinations = this.#destinationsInCategory(category.id);
        let count = destinations.length;
        category.subCategories.asArray?.forEach((subCategory) => {
          count += this.#destinationsInCategory(subCategory.id).length;
        });
        const comingSoon = destinations.filter(
          (destination: WF.Destination) => destination.status === 'COMING_SOON'
        ).length;

        const showCategory = showEmptyCategories || count > 0;
        return when(
          showCategory,
          () => html`
            <wf-category-single
              .category=${category}
              .count=${count}
              .comingSoonCount=${comingSoon}
              @click=${() => selectCategory(category)}
            ></wf-category-single>
          `
        );
      }
    );

    return html`${showAll}${categoriesList}`;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    'wf-category-listing': CategoryListing;
  }
}
