import {Duration} from 'luxon';
import {
  faRoute,
  faTimer,
  faCircleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import {faArrowsUpDown, faCompass} from '@fortawesome/pro-regular-svg-icons';

@customElement('wf-route-info')
export class RouteInfo extends LitElement {
  #routing = new StoreController(this, routingStore);
  #settings = new StoreController(this, settingsStore);

  @consume({context: wayfinderContext})
  @property({attribute: false})
  public wayfinder!: WF.Wayfinder;

  get #floors(): WF.FloorDatabase {
    return this.wayfinder.database.floors;
  }

  get #targetFloor(): string | null {
    if (this.#floors.length < 2) return null;

    const id = this.#routing.store.to?.floor;
    if (!id) return null;
    const floor = this.#floors.asMap[id];
    return floor.shortname ?? floor.name;
  }

  #distanceFromMeters(meters: number): string {
    switch (this.#settings.store.behaviour.distanceUnits) {
      case 'meters':
        return `${Math.round(meters)}m`;
      case 'yards':
        return `${Math.round(meters * 1.09361)} yards`;
    }
  }

  #timeFromSeconds(seconds: number): string {
    const duration = Duration.fromObject({seconds});
    return duration.shiftTo('minutes').toHuman({
      unitDisplay: 'short',
      maximumFractionDigits: 0,
    });
  }

  protected override render(): unknown {
    // Shows distance, time etc
    const infoRow = when(
      this.#routing.store.routeInfo,
      ({distance, time}) => html`
        ${when(
          this.#targetFloor,
          (floor) => html`<div>${useIcon(faArrowsUpDown)} ${floor}</div>`
        )}
        <div>${useIcon(faRoute)} ${this.#distanceFromMeters(distance)}</div>
        <div>${useIcon(faTimer)} ${this.#timeFromSeconds(time)}</div>
      `
    );

    // Shows error message
    const errorRow = when(
      this.#routing.store.routeError,
      (error) =>
        html`<p class="error">${useIcon(faCircleExclamation)} ${error}</p>`
    );

    // Shows 'loading' spinner when route is being calculated
    const loadingRow = when(
      this.#routing.store.routeCalculating,
      () =>
        html`<p>
          ${useIcon(faCompass, {
            classes: 'fa-spin',
          })}
          Finding the best route...
        </p>`
    );

    return html`${infoRow}${errorRow}${loadingRow}`;
  }

  static override styles = [
    typographyStyles,
    fontAwesomeStyles,
    flexStyles,
    displayStyles,
    css`
      :host {
        padding: var(--acq-ui-padding);
      }
      p {
        margin: 0;
        text-align: center;
        width: 100%;
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-route-info': RouteInfo;
  }
}
