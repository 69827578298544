import '@/components/wf-location-listing';
import '@/components/buttons/wf-button';
import '@/components/buttons/wf-button-directions';

import type {PropertyValues} from 'lit';

@customElement('wf-amenity-info')
export class AmenityInfo extends LitElement {
  static override styles = [
    typographyStyles,
    fontAwesomeStyles,
    listingStyles,
    css`
      :host {
        /* Typography */
        color: var(--acq-text-muted-color, black);
        height: fit-content;
      }

      div {
        border-top: 1px solid var(--acq-panel-fg);
        padding: 10px;
        box-sizing: border-box;
        max-height: calc(
          var(--acq-ui-panel-max-height, 100%) - 3.125rem - 10px - 1px
        );
        overflow-y: auto; /* Add scroll for vertical overflow */
      }

      wf-button-directions {
        margin-bottom: 1rem;
      }

      section {
        p {
          padding: 0;
          margin: 0;
          box-sizing: border-box;
          a {
            color: inherit;
          }
        }
        ul {
          padding: 0;
          margin: 0;
          li {
            list-style: none;
            margin: 0;
          }
        }
      }
      section:not(:last-child) {
        margin-bottom: 30px;
      }
    `,
  ];

  @consume({context: wayfinderContext})
  @property({attribute: false})
  public wayfinder!: WF.Wayfinder;

  @property({type: Object})
  public amenity?: WF.Amenity;

  #buildSection(
    title: string,
    text: string | null = null,
    content?: ReturnType<typeof html>
  ) {
    return html`
      <section class=${camelCase(title.replaceAll(' ', '-'))}>
        <h3>${title}</h3>
        ${when(text, () => html`<p>${text}</p>`)} ${content}
      </section>
    `;
  }

  get #floors(): WF.FloorDatabase {
    return this.wayfinder.database.floors;
  }

  protected override willUpdate(_changedProperties: PropertyValues): void {
    super.willUpdate(_changedProperties);
    if (_changedProperties.has('amenity')) {
      this.amenity?.setActive(true);
    }
  }

  #destination = new StoreController(this, destinationStore);

  get floor(): string {
    return this.amenity ? this.#floors.asMap[this.amenity.floor]?.name : '';
  }

  protected override render() {
    const {showInfo, setShowInfo} = this.#destination.store;

    return when(
      this.amenity,
      (amenity) => html`
        <wf-location-listing
          .open=${showInfo}
          .amenity=${amenity}
          @click=${() => setShowInfo(!showInfo)}
        ></wf-location-listing>
        ${when(
          showInfo,
          () => html`
            <div>
              <wf-button-directions .amenity=${amenity}></wf-button-directions>
              ${when(amenity?.floor && this.#floors.length > 1, () =>
                this.#buildSection('Floor', this.floor)
              )}
              ${when(amenity?.location_description, () =>
                this.#buildSection('Location', amenity!.location_description)
              )}
            </div>
          `
        )}
      `
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-amenity-info': AmenityInfo;
  }
}
