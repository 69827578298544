import type {PropertyValues} from 'lit';
import type {RouteSearch} from '@/components/inputs/wf-route-search';

import '@/components/inputs/wf-route-search';
import '@/components/routing/wf-step-free-toggle';
import '@/components/routing/wf-swap-to-from';

@customElement('wf-routing-panel')
export class RoutingPanel extends LitElement {
  static override styles = [
    flexStyles,
    typographyStyles,
    displayStyles,
    cardStyles,
    css`
      :host(.card) {
        margin-bottom: 0 !important;
      }
      section {
        gap: 10px;
        margin: 10px;
      }
      wf-search-results {
        display: block;
        max-height: calc(var(--acq-ui-panel-max-height, 100%) - 350px);
        overflow-y: auto;
      }
    `,
  ];

  @consume({context: wayfinderContext})
  @property({attribute: false})
  public wayfinder!: WF.Wayfinder;

  #routing = new StoreController(this, routingStore);
  #search = new StoreController(this, searchStore);

  @state()
  lastRoute: null | {
    to: number[];
    from: number;
    stepFree: boolean;
  } = null;
  protected override willUpdate(_changedProperties: PropertyValues): void {
    super.willUpdate(_changedProperties);

    const {toIds, fromId, stepFree, setRouteCalculating} = this.#routing.store;
    if (toIds?.length && toIds[0] && fromId) {
      // We have a route request, check if we need to update the route
      if (
        !this.lastRoute ||
        this.lastRoute.to.join(',') !== toIds.join(',') ||
        this.lastRoute.from !== fromId ||
        this.lastRoute.stepFree !== stepFree
      ) {
        // Route has changed, update it
        setRouteCalculating(true);
        const request = {to: toIds, from: fromId, stepFree};
        this.#routing.store
          .requestRoute(request, this.wayfinder)
          .then((result) => {
            this.#updateSearch();
            // Zoom to the route
            if (result && this.wayfinder.settings.shouldZoomToRoute) {
              zoomToRouteLine(this.wayfinder, result);
            }
          });
        this.lastRoute = request;
      }
    } else if (this.lastRoute !== null) {
      // Partial route request, clear the route
      logger.debug('Clearing route');
      this.wayfinder.clearRoute();
    }
  }

  override async connectedCallback() {
    super.connectedCallback();
    // Focus on the 'from' field
    await this.updateComplete;
    this.#focusSearch('from');
  }

  override disconnectedCallback(): void {
    this.wayfinder.clearRoute();
  }

  get toName() {
    const {to} = this.#routing.store;
    if (to === null) return '';
    if ('name' in to) return to.name;
    if ('type' in to)
      return this.wayfinder.database.amenityTypes.asMap[to.type].name;
    return to.id;
  }

  get fromName() {
    const {from} = this.#routing.store;
    if (from === null) return '';
    if ('name' in from) return from.name;
    if ('type' in from)
      return this.wayfinder.database.amenityTypes.asMap[from.type].name;
    return from.id;
  }

  @state()
  public isSearching: 'to' | 'from' | undefined;

  /**
   * Used to update the search.
   * Pass no parameters to clear the search.
   */
  #updateSearch(direction?: 'to' | 'from', value?: string) {
    this.isSearching = direction;
    this.#search.store.setSearchValue(value);

    if (direction && value === '') {
      this.#routing.store.clear(direction);
    }
  }

  #focusSearch(direction: 'to' | 'from') {
    this.shadowRoot
      ?.querySelector<RouteSearch>(`wf-route-search[direction="${direction}"]`)
      ?.focusInput();
    this.isSearching = direction;
  }

  protected override render() {
    return html`
      <section class="flex justify-between flex-column">
        <wf-route-search
          direction="from"
          @focusin=${() => this.#focusSearch('from')}
          .searchValue=${this.fromName}
          @search=${({detail}: CustomEvent<string>) =>
            this.#updateSearch('from', detail)}
        ></wf-route-search>
        <wf-route-search
          direction="to"
          @focusin=${() => this.#focusSearch('to')}
          .searchValue=${this.toName}
          @search=${({detail}: CustomEvent<string>) =>
            this.#updateSearch('to', detail)}
        ></wf-route-search>
        <div class="flex flex-row justify-between">
          <wf-step-free-toggle></wf-step-free-toggle>
          <wf-swap-to-from></wf-swap-to-from>
        </div>
      </section>
      ${when(
        this.isSearching,
        (isSearching) =>
          html`<wf-search-results
            .searching=${isSearching}
            ?isRouting=${true}
          ></wf-search-results>`
      )}
    `;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    'wf-routing-panel': RoutingPanel;
  }
}
