export const flexStyles = css`
  /* Layout */
  .flex-column {
    flex-direction: column;
  }
  .flex-row {
    flex-direction: row;
  }

  /* Alignment */
  .align-center {
    align-items: center;
  }
  .align-start {
    align-items: flex-start;
  }
  .align-end {
    align-items: flex-end;
  }

  /* Justification */
  .justify-center {
    justify-content: center;
  }
  .justify-start {
    justify-content: flex-start;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .justify-between {
    justify-content: space-between;
  }
  .justify-around {
    justify-content: space-around;
  }

  /* Wrapping */
  .flex-wrap {
    flex-wrap: wrap;
  }
  .flex-no-wrap {
    flex-wrap: nowrap;
  }

  /* Sizing */
  .flex-grow {
    flex-grow: 1;
  }
  .flex-shrink {
    flex-shrink: 1;
  }
  .w-half {
    flex-basis: 50%;
  }
  .w-third {
    flex-basis: 33.3333%;
  }
  .max-height-100 {
    height: -webkit-fill-available;
    height: stretch;
    max-height: 100%;
  }
  .h-100 {
    height: 100%;
  }
  .w-100 {
    width: 100%;
  }
`;

export const positionStyles = css`
  .absolute {
    position: absolute;
  }
  .relative {
    position: relative;
  }
  .fixed {
    position: fixed;
  }
  .zero {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

export const displayStyles = css`
  .hidden {
    display: none;
  }
  .block {
    display: block;
  }
  .inline {
    display: inline;
  }
  .inline-block {
    display: inline-block;
  }
  .flex {
    display: flex;
  }
  .inline-flex {
    display: inline-flex;
  }
`;

export const pointerStyles = css`
  .no-touch {
    pointer-events: none;
  }
  .yes-touch {
    pointer-events: auto;
  }
`;
