import {faLocationArrow} from '@fortawesome/pro-solid-svg-icons';

import '@/components/buttons/wf-button-icon';

@customElement('wf-button-location')
export class ButtonLocation extends LitElement {
  override render() {
    return html`<wf-button-icon .icon=${faLocationArrow}></wf-button-icon> `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-button-location': ButtonLocation;
  }
}
