export const badgeStyles = css`
  .badge {
    /* Style */
    background-color: var(--acq-button-badge-bg);
    color: var(--acq-button-badge-fg);
    border-radius: 50%;
    padding: 0.25rem;
    margin-left: 0.25rem;
    display: inline-block;
    height: 0.75rem;
    min-width: 0.75rem;
    /* Typography */
    font-size: 0.688rem;
    line-height: 0.75rem;
    text-align: center;
  }
`;
