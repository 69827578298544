import {coerce, literal, object, string, union, type z} from 'zod';

import type {UISettings} from '@/stores/settings';
import {Interval, type Zone} from 'luxon';
export const days = ['mon', 'tue', 'wed', 'thur', 'fri', 'sat', 'sun'] as const;

/**
 * This function takes the opening hours and combines the days with the same opening and closing hours.
 * It also omits the days that are closed.
 */
export function groupDaysWithSameValue(
  week: WF.OpeningHours
): z.infer<typeof openingHourSchema>[] {
  const groups: [WF.OpeningHour][] = [];
  let group = 0;

  // Loop over each day
  for (let i = 0; i < days.length; i++) {
    const index = days[i] as keyof WF.OpeningHours;
    // Assign default text
    const element = {...week[index]};
    element.text ||= titleCase(days[i]);

    // Create the first group from the first open day
    if (groups.length === 0 && element.value) {
      groups[group] = [element];
      continue;
    }

    // Closed on this day, move onto next group
    if (!element.value) {
      group++;
      continue;
    }

    // Compare with the previous day
    const previousElement = groups[group]?.[groups[group].length - 1];
    if (
      previousElement?.value &&
      element.oh === previousElement.oh &&
      element.ch === previousElement.ch
    ) {
      // Same open and close hours, add to group
      groups[group].push(element);
    } else {
      // Different open and close hours, create new group
      group++;
      groups[group] = [element];
    }
  }

  // Iterate over the groups to set the text
  return groups.filter(Boolean).map((group) => {
    if (group.length === 1) {
      return group[0];
    } else {
      const first = group[0].text;
      const last = group[group.length - 1].text;
      return {...group[0], text: `${first} - ${last}`};
    }
  });
}

export function validTimezone(input: unknown): input is Zone<true> {
  if (typeof input !== 'string' || input === '') return false;
  // If the input is a string, check if it is a valid timezone
  return DateTime.local({zone: input}).zone.isValid;
}
/**
 * HH:MM 24-hour format, optional leading 0
 * @link https://stackoverflow.com/a/51177696/2527692
 */
const hhmm = string().regex(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);
const openingHourSchema = object({
  text: string(),
  value: coerce.number().pipe(union([literal(0), literal(1)])),
  oh: hhmm,
  ch: hhmm,
});
const openingHoursSchema = object({
  mon: openingHourSchema,
  tue: openingHourSchema,
  wed: openingHourSchema,
  thur: openingHourSchema,
  fri: openingHourSchema,
  sat: openingHourSchema,
  sun: openingHourSchema,
});
/** Check that provided opening hours are valid */
export function validOpeningHours(input: unknown): input is WF.OpeningHours {
  const {success} = openingHoursSchema.safeParse(input);
  return success;
}

/**
 * Takes the timezone and opening hours to calculate the status.
 */
export function calculateStatus(
  timezone: Zone<true>, // Already validated timezone
  openingHours: WF.OpeningHours,
  settings?: UISettings
) {
  let color: string | undefined, text: string | undefined;

  // Get current time in destination timezone
  const now = DateTime.local({zone: timezone});

  // Get today's opening and closing hours
  const day = days[now.weekday - 1];
  const {value, oh, ch} = openingHours[day];

  // Ignore invalid opening hours
  if (!(oh && ch)) return {color, text};

  // Check if the destination is closed today
  if (value === 1) {
    const is24Hours = oh === '00:00' && ch === '00:00';
    const open = DateTime.fromFormat(oh, 'HH:mm', {zone: timezone});
    const close = DateTime.fromFormat(ch, 'HH:mm', {zone: timezone});

    // Open / Close - lowest priority
    if (is24Hours || Interval.fromDateTimes(open, close).contains(now)) {
      color = 'green';
      text = 'Open';
    } else {
      color = 'red';
      text = 'Closed';
    }

    // Opening / Closing Soon - medium priority
    const soonDuration = settings?.behaviour.soonDuration ?? 60;
    if (open.minus({minutes: soonDuration}) <= now && now < open) {
      color = 'orange';
      text = `Opens at ${open.toFormat('t')}`;
    } else if (close.minus({minutes: soonDuration}) <= now && now < close) {
      color = 'orange';
      text = `Closes at ${close.toFormat('t')}`;
    }
  } else {
    color = 'red';
    text = 'Closed';
  }
  return {color, text};
}
