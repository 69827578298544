import '@/components/destinations/wf-category-listing';
import '@/components/destinations/wf-destination-listing';

@customElement('wf-category-destination-selector')
export class CategoryDestinationSelector extends LitElement {
  #selected = new StoreController(this, destinationStore);

  protected override render() {
    const {showDestinations} = this.#selected.store;
    return when(
      showDestinations,
      () => html`<wf-destination-listing></wf-destination-listing>`,
      () => html`<wf-category-listing class="scrollable"></wf-category-listing>`
    );
  }

  // We don't need a shadow DOM for this simple selector
  protected override createRenderRoot() {
    return this;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-category-destination-selector': CategoryDestinationSelector;
  }
}
