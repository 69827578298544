import {faAngleRight} from '@fortawesome/pro-solid-svg-icons';

@customElement('wf-category-single')
export class CategorySingle extends LitElement {
  static override styles = [
    typographyStyles,
    displayStyles,
    flexStyles,
    colourStyles,
    css`
      :host {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0.313rem 0.625rem; /* 5px 10px */
        height: 3.125rem; /* 50px */
      }

      /* Category Title */
      h2 {
        max-width: 27ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      /* Category Title - ALL */
      h2.all {
        color: var(--acq-text-accent-color, #4d4cab);
        font-weight: 700;
        line-height: 150%; /* 24px */
        text-decoration-line: underline;
      }

      /* Category info */
      span {
        color: var(--acq-text-muted-color, #5a5a5a);
        font-size: 0.75rem; /* 12px */
        font-weight: 300;
        line-height: 120%;
      }

      /* Icon */
      svg {
        width: 20px;
        height: 20px;
        margin: auto 0;
      }
    `,
  ];

  constructor() {
    super();
    this.category = 'ALL';
    this.count = 0;
  }

  override connectedCallback(): void {
    super.connectedCallback();
    this.role = 'listitem';
  }

  @property()
  category: WF.DestinationCategory | WF.AmenityType | 'ALL';

  @property({type: Number})
  count: number;

  @property({type: Number})
  comingSoonCount?: number;

  get #categoryDetailsHTML() {
    const count = html`${this.count}
    ${this.count === 1 ? 'Destination' : 'Destinations'}`;
    const comingSoonCount = when(
      this.comingSoonCount && this.comingSoonCount > 0,
      () =>
        html` |
          <span class="green">${this.comingSoonCount} coming soon</span>`
    );
    return html`<span>${count}${comingSoonCount}</span>`;
  }

  protected override render() {
    const headingClasses = {
      all: this.category === 'ALL',
    };
    return html`<div class="flex flex-column justify-center">
        <h2 class=${classMap(headingClasses)}>
          ${this.category === 'ALL' ? 'Show All' : this.category.name}
        </h2>
        <span>${this.#categoryDetailsHTML}</span>
      </div>
      ${useIcon(faAngleRight)} `;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    'wf-category-single': CategorySingle;
  }
}
