/**
 * Array Shuffle Generator
 * @link https://stackoverflow.com/a/75264560/2527692
 *
 * @example
 * let arr = [1,2,3,4,5,6,7]
 * console.log([...shuffle(arr)])
 */
export function* shuffle<T>(arr: T[]): IterableIterator<T> {
  arr = [...arr];
  while (arr.length) yield arr.splice((Math.random() * arr.length) | 0, 1)[0];
}

/**
 * Groups multiples of the same destination together.
 * @param destinations - The destinations to group
 * @param property - The property to group by
 * @param concatFloorNames - Concatenate the floor names into the first destination of the group
 * @returns The grouped destinations. Each entry is either a single destination or an array of matching destinations.
 */
export function groupDestinations(
  destinations: WF.Destination[],
  property: keyof WF.Destination,
  concatFloorNames = false
): DestinationGroup[] {
  const groupedDestinationsMap = destinations.reduce((acc, destination) => {
    const group = acc.get(destination[property]);
    if (group) {
      if (Array.isArray(group)) {
        // Multiple destinations - add to the group
        group.push(destination);
      } else {
        // Multiple destinations - create a group
        acc.set(destination[property], [group, destination]);
      }
    } else {
      // First destination
      acc.set(destination[property], destination);
    }
    return acc;
  }, new Map<WF.Destination[typeof property], DestinationGroup>());
  const groupedDestinations = Array.from(groupedDestinationsMap.values());

  if (concatFloorNames) {
    // We need to use index access to satisfy TypeScript when setting the property
    for (const [index, group] of groupedDestinations.entries()) {
      if (!Array.isArray(group)) continue;

      // Gather unique floor names
      const floorNames = new Set<string>();
      for (const destination of group) {
        floorNames.add(destination.floor_name);
      }

      (groupedDestinations[index] as WF.Destination[])[0].multi_floor_names =
        Array.from(floorNames).join(' | ');
    }
  }

  return groupedDestinations;
}
