export const themeStyles = css`
  :host,
  :root {
    /* Default theme variables */
    --acq-default-fonts: 'Lexend', sans-serif;
    --acq-font-family-headings: 'Lato', sans-serif;
    --acq-font-family-body: var(--acq-default-fonts);
    --acq-ui-padding: 10px;

    --acq-text-color: black;
    --acq-text-muted-color: #5a5a5a;
    --acq-text-accent-color: #4d4cab;

    --acq-button-fg: white;
    --acq-button-bg: black;
    --acq-button-active-fg: var(--acq-button-fg);
    --acq-button-active-bg: #4d4cab;
    --acq-button-hover-fg: var(--acq-button-fg);
    --acq-button-hover-bg: hsl(
      from var(--acq-button-active-bg) h s calc(l + 10)
    );
    --acq-button-badge-fg: var(--acq-button-fg);
    --acq-button-badge-bg: #eb5757;

    --acq-panel-fg: black;
    --acq-panel-bg: white;
    --acq-panel-accent: #f2f2f2;
    --acq-slim-heading-fg: white;
    --acq-slim-heading-bg: #828282;
  }

  /* Safari support - https://developer.mozilla.org/en-US/docs/Web/CSS/color_value/hsl#using_relative_colors_with_hsl */
  @supports (color: hsl(from red h s calc(l + 10%))) {
    :root,
    :host {
      --acq-button-hover-bg: hsl(
        from var(--acq-button-active-bg) h s calc(l + 10%)
      );
    }
  }
`;
