import '@/components/destinations/wf-destination-list-header';
import '@/components/wf-location-listing';
import {WithCurrentFloor} from '@/mixins/CurrentFloor';

@customElement('wf-destination-listing')
export class DestinationListing extends WithCurrentFloor {
  static override styles = [
    listingStyles,
    typographyStyles,
    css`
      .listing {
        max-height: calc(var(--acq-ui-panel-max-height, 100%) - 50px - 1px);
        overflow-y: auto;
      }
    `,
  ];

  #selected = new StoreController(this, destinationStore);
  #previous = new StoreController(this, returnStore);
  #settings = new StoreController(this, settingsStore);

  get #destinations(): WF.DestinationDatabase {
    return this.wayfinder.database.destinations;
  }
  get #destinationsByCategory(): WF.Destination[] {
    const {category, showDestinations} = this.#selected.store;

    if (!category) return showDestinations ? this.#destinations.asArray : [];
    return this.#destinations.asArray.filter((d: WF.Destination) =>
      d.categories.includes(category.id)
    );
  }

  get #sortedDestinationsByCategory(): WF.Destination[] {
    return this.#destinationsByCategory.sort((a, b) =>
      cleanSort(a.name, b.name)
    );
  }

  // Destinations on the same floor
  get #nearbySortedDestinationsByCategory(): DestinationGroup[] {
    const sorted = this.#sortedDestinationsByCategory.filter(
      ({floor}) => floor === this.currentFloorId
    );
    return groupDestinations(sorted, 'name');
  }
  // Destinations on other floors
  get #otherSortedDestinationsByCategory(): DestinationGroup[] {
    const sorted = this.#sortedDestinationsByCategory.filter(
      ({floor}) => floor !== this.currentFloorId
    );
    return groupDestinations(sorted, 'name');
  }

  #singleDestinationHTML(group: DestinationGroup) {
    const destination = Array.isArray(group) ? group[0] : group;
    // If there are multiple locations, show the count
    let count: number | undefined;
    if (Array.isArray(group) && group.length > 1) {
      count = group.length;
    }

    return html`<wf-location-listing
      .destination=${destination}
      .count=${count}
      @click=${() => this.#destinationClickHandler(group)}
    ></wf-location-listing>`;
  }

  #destinationClickHandler(group: DestinationGroup) {
    const category = this.#selected.store.category as WF.DestinationCategory;
    const {setPrevious} = this.#previous.store;
    const {selectDestination} = this.#selected.store;

    selectDestination(
      group,
      Array.isArray(group) ? 'destination-group' : 'destination'
    );
    setPrevious('categories', category);

    if (!Array.isArray(group)) {
      const {behaviour} = this.#settings.store;
      // Show the floor of the destination
      this.wayfinder.showFloor(group.floor);

      // Zoom to destination
      if (behaviour.zoomToDestination) {
        zoomToDestination(this.wayfinder, group);
      }
    }
  }

  protected override render(): unknown {
    const category = this.#selected.store.category as WF.DestinationCategory;

    const header = html`<wf-destination-list-header
      .category=${category}
    ></wf-destination-list-header>`;

    const _nearby = this.#nearbySortedDestinationsByCategory;
    const nearby = html`<wf-slim-heading
        data-testid="nearby-destinations-header"
      >
        Nearby Destinations
      </wf-slim-heading>
      ${when(
        _nearby.length,
        () =>
          repeat(
            _nearby,
            (group) => (Array.isArray(group) ? group[0].id : group.id),
            (group) => this.#singleDestinationHTML(group)
          ),
        () => html`<p>No nearby destinations</p>`
      )}`;

    const _other = this.#otherSortedDestinationsByCategory;
    const other = when(
      _other.length,
      () =>
        html`<wf-slim-heading data-testid="other-destinations-header">
            Other Destinations
          </wf-slim-heading>
          ${repeat(
            _other,
            (group) => (Array.isArray(group) ? group[0].id : group.id),
            (group) => this.#singleDestinationHTML(group)
          )}`
    );

    return html`${header}
      <div class="listing">${nearby} ${other}</div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-destination-listing': DestinationListing;
  }
}
