import {
  faMagnifyingGlass,
  faTableList,
  faRestroom,
} from '@fortawesome/pro-solid-svg-icons';

import '@/components/buttons/wf-button-icon';

@customElement('wf-navbar')
export class NavBar extends LitElement {
  static override styles = [
    css`
      :host {
        border-radius: 5px;
        background: var(--acq-button-bg, black);
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);
        height: fit-content;
      }

      /* Horizontal stack */
      :host(.flex-row) {
        wf-button-icon:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        wf-button-icon:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      /* Vertical Stack */
      :host(:not(.flex-row)) {
        wf-button-icon:first-child {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
        wf-button-icon:last-child {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
    `,
    flexStyles,
  ];

  #nav = new StoreController(this, navigationStore);
  #selected = new StoreController(this, destinationStore);
  #previous = new StoreController(this, returnStore);
  #settings = new StoreController(this, settingsStore);

  #buttons = [
    {key: 'search', text: 'Search', icon: faMagnifyingGlass},
    {key: 'categories', text: 'Categories', icon: faTableList},
    {key: 'amenities', text: 'Amenities', icon: faRestroom},
  ] as const satisfies NavButton[];

  /**
   * Get enabled buttons.
   * All are enabled by default,
   * but can be disabled in settings.
   */
  get #enabledButtons() {
    const {features} = this.#settings.store.settings;
    return this.#buttons.filter(({key}) => features[key]);
  }

  /**
   * Handler for navigation button click
   * Clears categories, destinations etc
   * Sets active button
   */
  #clickHandler(key: NavButton['key']) {
    const {clearCategory, clearDestination} = this.#selected.store;
    const {active, setActive} = this.#nav.store;
    const {clearPrevious} = this.#previous.store;

    clearCategory();
    clearDestination();
    clearPrevious();

    if (key === active) setActive(null);
    else setActive(key);
  }

  override render() {
    const {active} = this.#nav.store;
    return repeat(
      this.#enabledButtons,
      ({key}: NavButton) => key,
      ({key, text, icon}: NavButton) => html`
        <wf-button-icon
          .icon="${icon}"
          ?active=${active === key}
          @click=${() => this.#clickHandler(key)}
          title=${text}
          role="tab"
          aria-controls="${key}-panel"
        ></wf-button-icon>
      `
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-navbar': NavBar;
  }
}
