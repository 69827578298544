import {default as isMobileJS} from 'ismobilejs';

/**
 * Utility to check if mobile features should be enabled.
 *
 * During testing:
 * - use `vi.stubGlobal('MOBILE', true)` to force mobile features or
 * - use `vi.stubGlobal('MOBILE', false)` to force desktop features.
 *
 * During development:
 * - use `?mobile=true` in the URL to force mobile features or
 * - use `?mobile=false` to force desktop features.
 */
export function isMobile(): boolean {
  if (
    import.meta.env.MODE === 'test' &&
    'MOBILE' in window &&
    window.MOBILE !== undefined
  ) {
    // Return testing value if being overriden
    return window.MOBILE;
  }

  if (import.meta.env.DEV) {
    // Return URL parameter if being overriden
    const params = useSearchParams();
    if (params.has('mobile')) {
      return params.get('mobile') === 'true';
    }
  }

  return isMobileJS().phone;
}

declare global {
  interface Window {
    MOBILE?: boolean;
  }
}
