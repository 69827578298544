import {
  faChevronRight,
  faChevronDown,
  faLocationDot,
} from '@fortawesome/pro-solid-svg-icons';
import type {PropertyValues} from 'lit';

@customElement('wf-location-listing')
export class LocationListing extends LitElement {
  #settings = new StoreController(this, settingsStore);

  @consume({context: wayfinderContext})
  @property({attribute: false})
  public wayfinder!: WF.Wayfinder;

  override connectedCallback(): void {
    super.connectedCallback();
    this.role = 'listitem';
  }

  override firstUpdated(p: PropertyValues): void {
    super.firstUpdated(p);
    if (!this.destination && !this.amenity) {
      throw new Error('No destination or amenity provided');
    }
    if (this.destination && this.amenity) {
      throw new Error('Only one of destination or amenity should be provided');
    }

    this.title = this.#name;
  }

  @property({type: Object})
  destination?: WF.Destination;

  @property({type: Object})
  amenity?: WF.Amenity;

  /** If there are multiple of the same destination/amenity,
   * this can be used to show a count
   * e.g. "2 Locations"
   */
  @property({type: Number})
  count?: number;

  get #logo(): string {
    if (this.amenity) {
      const amenityTypes = this.wayfinder.database.amenityTypes;
      return amenityTypes.asMap[this.amenity.type]?.url;
    } else {
      return this.destination!.logo;
    }
  }

  get #name(): string {
    if (this.amenity) {
      const amenityTypes = this.wayfinder.database.amenityTypes;
      return amenityTypes.asMap[this.amenity.type]?.name;
    } else {
      return this.destination!.name;
    }
  }

  get #multipleFloors(): boolean {
    return this.wayfinder.database.floors.length > 1;
  }

  get #location(): string | undefined {
    if (!this.#multipleFloors) return;

    if (this.count) {
      return `${this.count} Location${this.count === 1 ? '' : 's'}`;
    }

    // TODO: Handle multiple buildings
    const id = this.amenity?.floor ?? this.destination?.floor;
    if (!id) return;
    const floorDb = this.wayfinder.database.floors;
    const floor = floorDb.asMap[id];

    if (this.amenity) {
      return when(
        this.amenity.location_description,
        (description) => `${floor.name} | ${description}`,
        () => floor.name
      );
    } else if (this.destination) {
      return (
        this.destination.multi_floor_names ??
        this.destination.floor_name ??
        floor.name
      );
    } else return;
  }

  get #status(): ReturnType<typeof html> {
    let color: string | undefined, text: string | undefined;

    // If project has valid timezone and opening hours
    const timezone = this.wayfinder.settings.timezone;
    if (
      validTimezone(timezone) &&
      validOpeningHours(this.destination?.opening_arr)
    ) {
      const status = calculateStatus(
        timezone,
        this.destination.opening_arr,
        this.#settings.store.settings
      );
      color = status.color;
      text = status.text;
    }

    // Explicit status - highest priority
    switch (this.destination?.status) {
      case 'COMING_SOON':
        color = 'blue';
        text = 'Coming Soon';
        break;
      case 'NEW':
        color = 'blue';
        text = 'New';
        break;
      case 'TEMPORARILY_CLOSED':
        color = 'red';
        text = 'Temporarily Closed';
        break;
    }

    return when(
      text,
      (text) => html`<span class="${ifDefined(color)}">${text}</span>`
    );
  }

  @property({type: Boolean})
  open?: boolean;

  get #chevron() {
    return useIcon(this.open ? faChevronDown : faChevronRight);
  }

  protected override render() {
    if (!this.destination && !this.amenity) return nothing;

    const _name = this.#name;
    const _location = this.#location;
    const _status = this.#status;

    return html`${when(
        this.#logo,
        (logo) => html`<img class="logo" src=${logo} alt=${_name} /> `,
        () =>
          html`<span class="logo icon flex justify-center align-center">
            ${useIcon(faLocationDot)}
          </span>`
      )}
      <div class="info">
        <h2>${_name}</h2>
        ${when(_status, (status) => status)}
        ${when(_location && _status, () => html`<span> | </span>`)}
        ${when(
          _location,
          (location) => html`<span class="location">${location}</span>`
        )}
      </div>
      ${when(
        this.open !== undefined,
        () =>
          html`<span class="icon flex justify-center align-center">
            ${this.#chevron}
          </span>`
      )}`;
  }

  static override styles = [
    typographyStyles,
    flexStyles,
    displayStyles,
    colourStyles,
    css`
      :host {
        display: flex;
        flex-direction: row;
        padding: 0.313rem 0.625rem; /* 5px 10px */
        gap: 0.625rem; /* 10px */
        height: 3.125rem; /* 50px */
        align-items: center;
      }

      /* Destination Title */
      h2 {
        max-width: 25ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      /* Destination info */
      span {
        color: var(--acq-text-muted-color, #5a5a5a);
        font-size: 0.75rem; /* 12px */
        font-weight: 300;
        line-height: 120%;
      }

      .icon {
        /* Style */
        background: none;
        border: none;

        /* Layout */
        width: 3.125rem; /* 50px */
        height: 3.125rem; /* 50px */

        /* Icon within */
        svg {
          width: 20px;
          height: 20px;
        }
      }

      /* Image or placeholder */
      .logo {
        width: 30px;
        height: 30px;
        object-fit: contain;
        cursor: unset;
      }

      .info {
        flex-grow: 1;
        line-height: 1rem; /* 16px */
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-location-listing': LocationListing;
  }
}
