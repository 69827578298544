import {faArrowLeft} from '@fortawesome/pro-solid-svg-icons';
import {BaseButton} from './wf-button';

@customElement('wf-button-return')
export class ButtonReturn extends BaseButton {
  static override styles = [
    ...super.styles,
    fontAwesomeStyles,
    css`
      :host {
        margin: 10px;
        display: block;
        width: fit-content;
      }
    `,
  ];

  get #text() {
    const {previous, data} = this.#previous.store;
    switch (previous) {
      case 'search':
        return 'Back to search results';
      case 'categories':
      case 'amenities':
        return `Back to ${previous}`;
      case 'destination':
        return `Back to ${(data as WF.Destination).name}`;
      case 'destination-group':
        return `Back to ${(data as WF.Destination[])[0].name}`;
      default:
        return 'Back';
    }
  }

  #previous = new StoreController(this, returnStore);
  #destination = new StoreController(this, destinationStore);
  #nav = new StoreController(this, navigationStore);
  #settings = new StoreController(this, settingsStore);

  @consume({context: wayfinderContext})
  @property({attribute: false})
  public wayfinder!: WF.Wayfinder;

  #clickHandler() {
    const {previous, data, setPrevious} = this.#previous.store;
    const {setActive} = this.#nav.store;
    const {
      selectCategory,
      clearDestination,
      selectDestination,
      destination,
      discriminator,
    } = this.#destination.store;
    const {behaviour} = this.#settings.store;

    switch (previous) {
      case 'search':
        // The search bar pre-fills itself if the previous state was 'Search'
        setActive(previous);
        clearDestination();
        break;
      case 'categories':
      case 'amenities':
        selectCategory(data as WF.DestinationCategory | WF.AmenityType);
        clearDestination();
        setActive(previous);
        break;
      case 'destination':
      case 'amenity':
        if (destination && discriminator)
          setPrevious(discriminator, destination);
        selectDestination(data as WF.Destination | WF.Amenity, previous);
        if (behaviour.zoomToDestination) {
          if (discriminator === 'destination') {
            zoomToAmenity(this.wayfinder, data as WF.Amenity);
          } else {
            zoomToDestination(this.wayfinder, data as WF.Destination);
          }
        }
        setActive(null);
        break;
      case 'destination-group':
        if (destination && discriminator)
          setPrevious(discriminator, destination);
        selectDestination(data as WF.Destination[], 'destination-group');
        setActive(null);
        break;
    }

    document.dispatchEvent(new CustomEvent('focus-search'));
  }

  override connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('click', this.#clickHandler);
  }
  override disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('click', this.#clickHandler);
  }

  override render() {
    return html`${useIcon(faArrowLeft)} <span>${this.#text}</span>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-button-return': ButtonReturn;
  }
}
