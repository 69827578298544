import '@/components/buttons/wf-button';
import {faDiamondTurnRight} from '@fortawesome/pro-solid-svg-icons';

@customElement('wf-button-directions')
export class DirectionsButton extends LitElement {
  #settings = new StoreController(this, settingsStore);
  #routing = new StoreController(this, routingStore);
  #nav = new StoreController(this, navigationStore);
  #selected = new StoreController(this, destinationStore);
  #search = new StoreController(this, searchStore);

  @property({type: Object})
  destination?: WF.Destination;

  @property({type: Object})
  amenity?: WF.Amenity;

  override connectedCallback(): void {
    super.connectedCallback();
    if (!this.destination && !this.amenity) {
      throw new Error('No destination or amenity provided');
    }
    if (this.destination && this.amenity) {
      throw new Error('Only one of destination or amenity should be provided');
    }

    if (this.destination || this.amenity) {
      this.addEventListener('click', this.#clickHandler);
    }
  }

  override disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('click', this.#clickHandler);
  }

  #clickHandler(_: MouseEvent): void {
    const {setActive} = this.#nav.store;
    const {setTo, clearRouteMeta} = this.#routing.store;
    const {clearDestination, clearCategory} = this.#selected.store;
    const {clearSearchValue} = this.#search.store;

    clearRouteMeta();
    setTo(this.amenity ?? this.destination!);
    setActive('routing');
    clearDestination();
    clearCategory();
    clearSearchValue();
  }

  protected override render(): unknown {
    return when(
      this.#settings.store.features.routing,
      () =>
        html`<wf-button>
          <div>${useIcon(faDiamondTurnRight)} Directions</div>
        </wf-button>`,
      () => nothing
    );
  }

  static override styles = [
    fontAwesomeStyles,
    typographyStyles,
    css`
      :host {
        display: block;
      }
      wf-button {
        font-size: 1rem;
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-button-directions': DirectionsButton;
  }
}
