import {Exome} from 'exome';
import {logger} from '@/utils/logger';
import type {RouteResponse} from '@/types/wayfinder';

class RoutingStore extends Exome {
  public to: WF.Amenity | WF.Destination | Unit | null = null;
  public get toIds() {
    if (!this.to) return;
    return Array.isArray(this.to.node) ? this.to.node : [this.to.node];
  }

  public from: WF.Amenity | WF.Destination | Unit | null = null;
  public get fromId() {
    if (!this.from) return;
    return Array.isArray(this.from.node) ? this.from.node[0] : this.from.node;
  }

  public swap() {
    const temp = this.to;
    this.to = this.from;
    this.from = temp;
  }

  public setTo(to: WF.Destination | WF.Amenity | Unit) {
    if (!(Array.isArray(to.node) ? to.node[0] : to.node)) {
      logger.warn('No node found for "to" destination', to);
      this.setRouteError(
        'Sorry, there is no route available for this destination.'
      );
    }
    this.to = to;
  }
  public setFrom(from: WF.Destination | WF.Amenity | Unit) {
    if (!(Array.isArray(from.node) ? from.node[0] : from.node)) {
      logger.warn('No node found for "from" destination', from);
      this.setRouteError(
        'Sorry, there is no route available for this destination.'
      );
    }
    this.from = from;
  }
  public clear(direction?: 'to' | 'from') {
    this.clearRouteMeta();
    if (direction !== undefined) {
      this[direction] = null;
    } else {
      this.to = null;
      this.from = null;
      this.stepFree = false;
    }
  }

  public stepFree = false;
  public setStepFree(stepFree: boolean) {
    this.stepFree = stepFree;
  }
  public toggleStepFree() {
    this.stepFree = !this.stepFree;
  }

  public routeCalculating = false;
  public routeInfo: RouteResponse['status'] | null = null;
  public routeError?: string = undefined;

  public clearRouteMeta() {
    this.routeInfo = null;
    this.routeError = undefined;
  }
  public setRouteInfo(info: RouteResponse['status']) {
    this.routeInfo = info;
    this.routeCalculating = false;
  }
  public setRouteError(error: string) {
    this.routeInfo = null;
    this.routeError = error;
    this.routeCalculating = false;
  }
  public setRouteCalculating(isCalculating: boolean) {
    this.routeCalculating = isCalculating;
  }

  public get showRouteInfo(): boolean {
    return (
      this.routeInfo !== null ||
      this.routeError !== undefined ||
      this.routeCalculating
    );
  }

  public async requestRoute(
    {
      to,
      from,
      stepFree,
    }: {
      to: number[];
      from: number;
      stepFree: boolean;
    },
    wayfinder: WF.Wayfinder
  ): Promise<RouteResponse | void> {
    this.clearRouteMeta();
    logger.log(`Asking Wayfinder to route from ${from} to ${to}`);
    return wayfinder
      .routeBetween(from, to, stepFree ? 1 : 0)
      .then(async (result: RouteResponse) => {
        logger.debug('Route result', result);
        this.setRouteInfo(result.status);
        if (result.waypoints[0]) {
          const newFloor = result.waypoints[0].floor.id;

          // Show the floor of the destination
          await wayfinder.showFloor(newFloor);
        }
        return result;
      })
      .catch((e) => {
        this.setRouteError('No Directions are available for this route');
        logger.error('routeBetween', e);
      });
  }
}

export const routingStore = new RoutingStore();
