export const typographyStyles = css`
  :host {
    font-family: var(--acq-font-family, var(--acq-default-fonts, sans-serif));
    color: var(--acq-text-color, black);
    font-feature-settings: 'liga' off;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem; /* 14px */
    line-height: 1.2;
  }

  button,
  input {
    font-family: inherit;
  }

  p,
  li {
    font-size: 1rem; /* 16px */
    font-weight: 400;
  }

  h1,
  h2,
  h3 {
    margin: 0;
    line-height: 1.2;
  }

  h2 {
    font-size: 1rem; /* 16px */
    font-weight: 500;
  }

  h3 {
    font-weight: 700;
    color: var(--acq-text-color, black);
  }
`;
