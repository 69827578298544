/** Zoom to a {@link WF.Destination} or group of Destinations */
export async function zoomToDestination(
  wayfinder: WF.Wayfinder,
  destination: DestinationGroup
) {
  let bounds: WF.Bounds;
  if (Array.isArray(destination)) {
    const allBounds = destination.map((d) => d.meshObject.bounds);
    // Since we can't instantiate an empty Bounds object, we need to shift the first element
    bounds = allBounds.shift()!;
    // Loop over any remaining bounds and combine them
    allBounds.forEach((b) => bounds.combine(b));
  } else {
    bounds = destination.meshObject.bounds;
  }
  const {idleMapAngle, idleMapTilt} = wayfinder.settings;
  return wayfinder.animateToBounds(bounds, idleMapAngle, idleMapTilt, true);
}

/** Zoom to an {@link WF.Amenity} */
export async function zoomToAmenity(
  wayfinder: WF.Wayfinder,
  amenity: WF.Amenity
) {
  const bounds = amenity.overlay.boundingBox;
  const {idleMapAngle, idleMapTilt} = wayfinder.settings;
  return wayfinder.animateToBounds(bounds, idleMapAngle, idleMapTilt, true);
}

/** Zoom to a {@link WF.Floor} */
export async function zoomToFloor(
  wayfinder: WF.Wayfinder,
  floor: WF.Floor['id']
): Promise<void>;
export async function zoomToFloor(
  wayfinder: WF.Wayfinder,
  floor: WF.Floor
): Promise<void>;
export async function zoomToFloor(
  wayfinder: WF.Wayfinder,
  floor: WF.Floor | WF.Floor['id']
) {
  const floorId = typeof floor === 'object' ? floor.id : floor;
  const bounds = wayfinder.getFloorBounds(
    floorId,
    window.Wayfinder.BoundaryTypeFloorActiveArea
  );
  if (bounds?.valid) {
    const {idleMapAngle, idleMapTilt} = wayfinder.settings;
    return wayfinder.animateToBounds(bounds, idleMapAngle, idleMapTilt, true);
  }
}

/** Zoom to X Y Z coordinates */
export async function zoomToPosition(
  wayfinder: WF.Wayfinder,
  position: {x: number; y: number; z: number}
) {
  wayfinder.zoomLevel = 100;
  return wayfinder.panTo(position);
}
