@customElement('wf-step-free-toggle')
export class StepFreeToggle extends LitElement {
  static override styles = [
    typographyStyles,
    toggleSwitchStyles,
    css`
      :host {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;

        /* Typography */
        font-size: 0.75rem; /* 12px */
        font-weight: 300;
        color: var(--acq-text-muted-color);
      }
    `,
  ];

  #route = new StoreController(this, routingStore);
  protected override render() {
    const {stepFree} = this.#route.store;
    return html`
      <label class="switch">
        <input
          type="checkbox"
          name="step-free"
          ?checked=${stepFree}
          @change=${() => this.#route.store.toggleStepFree()}
        />
        <span class="slider"></span>
      </label>

      Step-free route
    `;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    'wf-step-free-toggle': StepFreeToggle;
  }
}
