import '@/components/wf-location-listing';

@customElement('wf-destination-group-listing')
export class DestinationGroupListing extends LitElement {
  #selected = new StoreController(this, destinationStore);
  #previous = new StoreController(this, returnStore);

  @property({type: Array})
  destinations: WF.Destination[] = [];

  #destinationClickHandler(destination: WF.Destination) {
    const {setPrevious} = this.#previous.store;
    const {selectDestination} = this.#selected.store;

    selectDestination(destination, 'destination');
    setPrevious('destination-group', this.destinations);
  }

  protected override render(): unknown {
    return repeat(
      this.destinations,
      ({id}) => id,
      (destination) => {
        return html`<wf-location-listing
          .destination=${destination}
          @click=${() => this.#destinationClickHandler(destination)}
        ></wf-location-listing>`;
      }
    );
  }

  static override styles = [listingStyles, typographyStyles];
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-destination-group-listing': DestinationGroupListing;
  }
}
