import {faArrowUpArrowDown} from '@fortawesome/pro-solid-svg-icons';

@customElement('wf-swap-to-from')
export class SwapToFrom extends LitElement {
  static override styles = [
    typographyStyles,
    flexStyles,
    displayStyles,
    css`
      :host {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
      button {
        /* Style */
        border: none;
        background: var(--acq-panel-bg);
        border-radius: 5px;
        padding: 5px;
        cursor: pointer;

        /* Typography */
        font-size: 0.75rem; /* 12px */
        font-weight: 300;

        svg {
          color: var(--acq-text-color);
          width: 1.25rem;
          height: 1.25rem;
          margin-right: 5px;
        }
      }

      button:hover {
        color: var(--acq-button-active-bg, rgb(77, 76, 171));
        svg {
          color: inherit;
        }
      }
    `,
  ];

  #route = new StoreController(this, routingStore);
  override connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('click', this.#route.store.swap);
  }
  override disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('click', this.#route.store.swap);
  }

  protected override render() {
    return html`
      <button class="flex align-center">
        ${useIcon(faArrowUpArrowDown)} Swap
      </button>
    `;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    'wf-swap-to-from': SwapToFrom;
  }
}
