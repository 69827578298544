import '@/sentry';

import '@/components/wf-navbar';
import '@/components/buttons/wf-button';
import '@/components/buttons/wf-button-location';
import '@/components/wf-floor-selector';
import '@/components/wf-zoom-controls';
import '@/components/destinations/wf-category-destination-selector';
import '@/components/destinations/wf-destination-group-listing';
import '@/components/amenities/wf-amenity-types-selector';
import '@/components/search/wf-search-panel';
import '@/components/destinations/wf-destination-info';
import '@/components/amenities/wf-amenity-info';
import '@/components/buttons/wf-button-return';
import '@/components/routing/wf-routing-panel';
import '@/components/routing/wf-route-info';

import {WithEventHandlers} from '@/mixins/WayfinderEventHandlers';
import type {PropertyValueMap} from 'lit';
import type {UISettings} from '@/stores/settings';
import {version} from '../package.json';
import {configureAnalytics, logEvent} from '@acquireweb/analytics';

/**
 * Wayfinder Map UI
 */
@customElement('wf-map-ui')
export class WayfinderMapUi extends WithEventHandlers {
  public get VERSION() {
    return version;
  }

  static override styles = [
    themeStyles,
    cardStyles,
    flexStyles,
    positionStyles,
    displayStyles,
    pointerStyles,
    css`
      :host {
        box-sizing: border-box;
        display: block;
        overflow: hidden;
        position: relative;
      }

      .overlay {
        width: 100%;
        height: 100%;
        max-height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        box-sizing: border-box;
        padding: var(--acq-ui-padding, 0);
        gap: var(--acq-ui-padding, 0);

        > div {
          /* Row height should not exceed full height, minus the other row, minus the gap */
          max-height: calc(100% - 50px - var(--acq-ui-padding, 0));
          gap: var(--acq-ui-padding, 0);
          > div {
            /* Corner contents should not exceed height of row */
            height: stretch;
            height: -webkit-fill-available;
            max-height: 100%;
          }
        }

        footer,
        .gap {
          gap: var(--acq-ui-padding, 0);
        }
      }
    `,
  ];

  @provide({context: wayfinderContext})
  @property({attribute: false})
  public override wayfinder!: WF.Wayfinder;

  @property({attribute: false})
  public settings: Partial<UISettings> = {};

  #loadedFromJSON = false;

  #height?: number;
  protected override willUpdate(
    _changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
  ): void {
    super.willUpdate(_changedProperties);

    // If the wayfinder instance is set and it hasn't been loaded yet, attatch to it
    if (
      _changedProperties.has('wayfinder') &&
      this.wayfinder !== undefined &&
      !this._wayfinderLoaded
    ) {
      this.#attatchToWayfinder(this.wayfinder);
    }

    // If the settings have changed, update the UI
    if (_changedProperties.has('settings')) {
      this.#settings.store.updateSettings(this.settings);
    }

    // Observe changes to the height of the component
    const height = this.wayfinder?.size.height;
    if (notEqual(height, this.#height)) {
      this.#height = height;
      this.#updateHeightVariable(height);
    }
  }

  @state()
  protected _wayfinderLoaded = false;

  #nav = new StoreController(this, navigationStore);
  #destination = new StoreController(this, destinationStore);
  #previous = new StoreController(this, returnStore);
  #settings = new StoreController(this, settingsStore);
  #routing = new StoreController(this, routingStore);
  #search = new StoreController(this, searchStore);

  /** The panel for selecting categories, amenity types, etc. **/
  get #menuPanel() {
    const {active} = this.#nav.store;
    return when(active, () =>
      cache(
        choose(active, [
          [
            'search',
            () =>
              html`<wf-search-panel
                id="search-panel"
                title="Search"
                role="tabpanel"
                class="card yes-touch"
              ></wf-search-panel>`,
          ],
          [
            'categories',
            () => html`
              <wf-category-destination-selector
                id="categories-panel"
                role="tabpanel"
                title="Destinations"
                class="card yes-touch"
              ></wf-category-destination-selector>
            `,
          ],
          [
            'amenities',
            () => html`
              <wf-amenity-types-selector
                id="amenities-panel"
                role="tabpanel"
                title="Amenities"
                class="card yes-touch"
              ></wf-amenity-types-selector>
            `,
          ],
          [
            'routing',
            () => html`
              <div class="gap flex flex-column align-center justify-center">
                <wf-routing-panel class="card yes-touch"></wf-routing-panel>
                ${when(
                  this.#settings.store.features.routingInfo &&
                    this.#routing.store.showRouteInfo,
                  () =>
                    html`<wf-route-info
                      class="flex justify-between align-center card  yes-touch"
                    ></wf-route-info> `
                )}
              </div>
            `,
          ],
        ])
      )
    );
  }

  /** The panel for displaying a destination or amenity */
  get #detailsPanel() {
    // The panel for destination info
    const {discriminator, destination} = this.#destination.store;
    const details = when(destination, (location) =>
      choose(discriminator, [
        [
          'destination',
          () =>
            html`<wf-destination-info
              class="yes-touch"
              .destination=${location as WF.Destination}
            ></wf-destination-info>`,
        ],
        [
          'amenity',
          () =>
            html`<wf-amenity-info
              class="yes-touch"
              .amenity=${location as WF.Amenity}
            ></wf-amenity-info>`,
        ],
        [
          'destination-group',
          () =>
            html`<wf-destination-group-listing
              class="yes-touch"
              .destinations=${location as WF.Destination[]}
            ></wf-destination-group-listing>`,
        ],
      ])
    );

    // Hide the return button if there is no previous category
    const {previous} = this.#previous.store;

    return when(
      discriminator,
      () =>
        html`<div class="h-100 w-100">
          <div class="card yes-touch">${details}</div>
          ${when(
            previous,
            () => html`<wf-button-return class="yes-touch"></wf-button-return>`
          )}
        </div>`
    );
  }

  // Elements in the top left of the map
  get #layoutTopLeft(): ReturnType<typeof html> {
    // The navbar
    const navbar = html`<wf-navbar
      role="tablist"
      aria-orientation="vertical"
      class="flex flex-column yes-touch"
    ></wf-navbar>`;
    return html`<div class="flex flex-row align-start max-height-100">
      ${navbar}${this.#menuPanel}${this.#detailsPanel}
    </div>`;
  }

  // Elements in the top right of the map
  get #layoutTopRight(): ReturnType<typeof html> {
    const {floorSelector} = this.#settings.store.features;
    return when(
      floorSelector,
      () => html`<wf-floor-selector class="yes-touch"></wf-floor-selector>`
    );
  }

  // Elements in the bottom left of the map
  get #layoutBottomLeft(): ReturnType<typeof html> {
    const {currentLocation} = this.#settings.store.features;
    return when(
      currentLocation,
      () => html`<wf-button-location class="yes-touch"></wf-button-location>`
    );
  }

  // Elements in the bottom right of the map
  get #layoutBottomRight(): ReturnType<typeof html> {
    const {zoomControls} = this.#settings.store.features;
    return when(
      zoomControls,
      () => html`<wf-zoom-controls class="yes-touch"></wf-zoom-controls>`
    );
  }

  get #mobileUI() {
    return html`<div class="overlay flex flex-column justify-between no-touch">
      <div class="flex flex-row no-touch">
        ${this.#menuPanel} ${this.#detailsPanel}
      </div>

      <footer class="flex flex-row justify-center align-end no-touch">
        <div class="w-third">${this.#layoutBottomLeft}</div>
        <div class="justify-center flex w-third">
          <wf-navbar class="flex flex-row yes-touch"></wf-navbar>
        </div>
        <div class="w-third">${this.#layoutTopRight}</div>
      </footer>
    </div>`;
  }
  get #desktopUI() {
    return html`
      <div
        class="no-touch flex flex-column overlay flex-no-wrap justify-between"
      >
        <div class="no-touch flex flex-row justify-between align-start">
          <div>${this.#layoutTopLeft}</div>
          <div>${this.#layoutTopRight}</div>
        </div>
        <div class="no-touch flex flex-row justify-between align-end">
          <div>${this.#layoutBottomLeft}</div>
          <div>${this.#layoutBottomRight}</div>
        </div>
      </div>
    `;
  }

  override render() {
    return html`${when(this._wayfinderLoaded, () =>
        when(
          isMobile(),
          () => this.#mobileUI,
          () => this.#desktopUI
        )
      )}<slot></slot>`;
  }

  override connectedCallback() {
    super.connectedCallback();

    // Attach to the wayfinder API if available
    if (this.wayfinder) {
      this.#attatchToWayfinder(this.wayfinder);
    }
  }

  #onLoad(wayfinder: WF.Wayfinder) {
    configureAnalytics({
      apiKey: this.wayfinder.database.apiKey,
    });
    logEvent({
      event: 'script_loaded',
      data: {
        service: 'web-map-ui',
        origin: window.location.origin,
        user_agent: window.navigator.userAgent,
        environment: import.meta.env.PROD ? 'live' : 'dev',
      },
    });

    logger.withTag('event').info('Wayfinder loaded');
    this._wayfinderLoaded = true;

    if (!this.#loadedFromJSON) {
      if (this.#settings.store.behaviour.zoomOnInitialLoad) {
        zoomToFloor(wayfinder, wayfinder.floorId);
      }
    }

    // Cleanup listeners
    wayfinder.removeEventListener('loaded', () => {
      this.#onLoad(wayfinder);
    });
  }

  #attatchToWayfinder(wayfinder: WF.Wayfinder) {
    this.#checkMapIsChild(wayfinder);

    // Handle Map App links
    wayfinder.addEventListener('mapapp', (e) =>
      this._mapAppHandler(e, wayfinder)
    );

    // Only initialise the UI once the wayfinder instance is loaded
    if (wayfinder.loaded) {
      this.#onLoad(wayfinder);
    } else {
      wayfinder.addEventListener('loaded', () => {
        this.#onLoad(wayfinder);
      });
    }

    wayfinder.addEventListener('error', (e) => this._errorHandler(e));

    wayfinder.addEventListener('mousedown', (e) => this._mouseDownHandler(e), {
      passive: true,
    });
    wayfinder.addEventListener('mousemove', (e) => this._mouseMoveHandler(e), {
      passive: true,
    });
    wayfinder.addEventListener('mouseup', (e) => this._mouseUpHandler(e), {
      passive: true,
    });

    if (import.meta.env.DEV) logger.debug('Wayfinder', wayfinder);

    const now = DateTime.local({zone: wayfinder.settings.timezone!});
    logger.info('Local Time', now.toFormat('HH:mm'));
  }

  /**
   * To ensure the correct layout,
   * we need to check that the map component is a direct child of this component.
   */
  #checkMapIsChild(wayfinder: WF.Wayfinder) {
    // Check that we have a child node
    if (this.childElementCount === 0) {
      // Map is not a child of us!
      logger.warn(
        'Please ensure the root div for the map is a child of this component'
      );
    } else if (this.childElementCount > 1) {
      // Too many children
      logger.warn(
        'Please ensure the root div for the map is the only child of this component'
      );
    }

    // Check if the map is a child of this component
    const mapElement = wayfinder.rootDIV;
    const childElement = this.children.item(0);
    if (mapElement !== childElement) {
      logger.warn(
        'Please ensure the root div for the map is a child of this component'
      );
    }
  }

  #updateHeightVariable(height: number) {
    const heightValue = Math.round(height * 0.8);
    this.style.setProperty('--acq-ui-panel-max-height', `${heightValue}px`);
  }

  /**
   * Cleanup the component
   */
  public destroy() {
    console.debug('Destroying Wayfinder Map UI');

    const {clearDestination, clearCategory} = this.#destination.store;
    const {clearPrevious} = this.#previous.store;
    const {clearActive} = this.#nav.store;
    const {clear: clearRoute} = this.#routing.store;
    const {clearSearchValue} = this.#search.store;
    const {resetSettings} = this.#settings.store;

    // Cleanup stores
    clearDestination();
    clearCategory();
    clearPrevious();
    clearActive();
    clearRoute();
    clearSearchValue();
    resetSettings();

    // @ts-expect-error - Remove the wayfinder instance
    this.wayfinder = undefined;

    // Unload Wayfinder
    this._wayfinderLoaded = false;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-map-ui': WayfinderMapUi;
  }
}
