const SEARCH_RESULTS_CACHE = new Map<string, SearchResult[]>();

/**
 * Main search function
 * Gathers {@link WF.Destination} and {@link WF.Amenity} search results,
 * then removes any duplicates and caches the result.
 */
export function search(
  wayfinder: WF.Wayfinder,
  searchValue: string | undefined
): SearchResult[] {
  if (!searchValue) return [];

  // Check for cached result
  if (SEARCH_RESULTS_CACHE.has(searchValue)) {
    return SEARCH_RESULTS_CACHE.get(searchValue)!;
  }

  // No cached result, run search
  const destinations = searchDestinations(wayfinder, searchValue);
  const amenities = searchAmenities(wayfinder, searchValue);
  const results = Array.prototype.concat(
    destinations,
    amenities
  ) as SearchResult[];

  // Remove any duplicate results
  const uniqueResultsMap = results.reduce((acc, result) => {
    if (!acc.has(String(result.id))) acc.set(String(result.id), result);
    return acc;
  }, new Map<string, SearchResult>());
  const uniqueResults = Array.from(uniqueResultsMap.values());

  // Sort the results alphabetically by name
  uniqueResults.sort((a, b) => {
    const aName =
      'name' in a ? a.name : wayfinder.database.amenityTypes.asMap[a.type].name;
    const bName =
      'name' in b ? b.name : wayfinder.database.amenityTypes.asMap[b.type].name;
    return cleanSort(aName, bName);
  });

  // Cache the result
  SEARCH_RESULTS_CACHE.set(searchValue, uniqueResults);
  return uniqueResults;
}

/** Search for destinations */
function searchDestinations(
  wayfinder: WF.Wayfinder,
  searchValue: string
): Array<WF.Destination & {discriminator: 'destination'}> {
  const _allDestinationCategories =
    wayfinder.database.destinationCategories.asArray;
  const _allDestinations = wayfinder.database.destinations.asArray;

  const name = _destinationsByName(_allDestinations, searchValue);
  const description = _destinationsByDescription(_allDestinations, searchValue);
  const category = _destinationsByCategory(
    _allDestinations,
    _allDestinationCategories,
    searchValue
  );
  const tags = _destinationsByTags(_allDestinations, searchValue);
  return Array.prototype.concat(name, description, category, tags).map((d) =>
    Object.assign(d, {
      discriminator: 'destination' as const,
    })
  );
}

/**
 * Search for amenities
 *
 * Since amenities do not have their own name,
 * we do a partial match on the type (category) name.
 */
function searchAmenities(
  wayfinder: WF.Wayfinder,
  searchValue: string
): Array<WF.Amenity & {discriminator: 'amenity'}> {
  // Get amenity types that match the search query
  const matchedTypes = wayfinder.database.amenityTypes.asArray.filter(
    ({name, disable_search}) =>
      disable_search !== 1 &&
      name?.toLowerCase().includes(searchValue.toLowerCase())
  );

  // Get amenities that have a type that matches the search query
  return wayfinder.database.amenities.asArray
    .filter((a: WF.Amenity) => matchedTypes.some((t) => t.id === a.type))
    .map((a) =>
      Object.assign(a, {
        discriminator: 'amenity' as const,
      })
    );
}

/** Partial match for search query in destination name */
function _destinationsByName(
  destinations: WF.Destination[],
  searchValue: string
): WF.Destination[] {
  return destinations.filter((d) =>
    d.name.toLowerCase().includes(searchValue.toLowerCase())
  );
}
/** Partial match for search query in destination description */
function _destinationsByDescription(
  destinations: WF.Destination[],
  searchValue: string
): WF.Destination[] {
  return destinations.filter((d) =>
    d.description?.toLowerCase().includes(searchValue.toLowerCase())
  );
}
/** Partial match for search query in destination category name */
function _destinationsByCategory(
  destinations: WF.Destination[],
  categories: WF.DestinationCategory[],
  searchValue: string
): WF.Destination[] {
  const matchedCategories = categories.filter((c) =>
    c.name.toLowerCase().includes(searchValue.toLowerCase())
  );
  return destinations.filter(({categories}) =>
    categories.some((c) => matchedCategories.some((mc) => mc.id === c))
  );
}
/** Exact match of tags */
function _destinationsByTags(
  destinations: WF.Destination[],
  searchValue: string
): WF.Destination[] {
  return destinations.filter((d) => {
    const tags = d.tags || [];
    return tags.some((t) => t.toLowerCase() === searchValue.toLowerCase());
  });
}

const COMMON_WORDS = new Set(['the']);
/**
 * Removes common words such as 'the' from the start of strings
 * Also trims whitespace from the start and end of strings
 * @example
 * clean('The Apple Store') // 'Apple Store'
 */
export function clean(string: string): string {
  // Convert the string to lowercase for case-insensitive comparison
  let cleanedString = string.trim();

  // Check if the first word is a common word and remove it if so
  const words = cleanedString.toLowerCase().split(' ');
  if (words[0] && COMMON_WORDS.has(words[0])) {
    cleanedString = cleanedString.split(' ').slice(1).join(' ');
  }

  return cleanedString;
}

/** Sorts results alphabetically, while ignoring common words such as 'the' */
export function cleanSort(a: string, b: string): number {
  return clean(a).localeCompare(clean(b), undefined, {sensitivity: 'base'});
}

/**
 * Helper function to mock search params in testing
 * NOTE: The `if` should be tree-shaken in production builds
 */
export function useSearchParams(): URLSearchParams {
  if (import.meta.env.MODE === 'test' && 'MAPAPP' in window)
    return new URLSearchParams(window.MAPAPP ?? window.location.search);
  return new URLSearchParams(window.location.search);
}

declare global {
  interface Window {
    MAPAPP?: string;
  }
}
