@customElement('wf-search-box')
export class SearchBox extends LitElement {
  static override styles = [
    typographyStyles,
    css`
      input {
        /* Typography */
        font-size: 1.25rem; /* 20px */
        line-height: 150%; /* 30px */

        /* Style */
        border: none;
        border-radius: 5px;
        width: 100%;
        margin: 0;
        padding: 0 20px;
        height: 3.125rem; /* 50px */
      }

      input::placeholder {
        color: #c4c4c4;
      }
    `,
  ];

  @property({type: Boolean})
  override autofocus = false;

  @query('input')
  input!: HTMLInputElement;

  override async connectedCallback(): Promise<void> {
    super.connectedCallback();
    await this.updateComplete;

    // Automatically focus() the input
    document.addEventListener('focus-search', () => this.focusInput());
    if (this.autofocus) this.focusInput();
  }
  override disconnectedCallback(): void {
    super.disconnectedCallback();
    // Remove event listeners
    document.removeEventListener('focus-search', () => this.focusInput());
  }

  /** Debounce the input. */
  #debounceTimeout: ReturnType<typeof setTimeout> | undefined;
  #firstInput = true;
  async #changeHandler(_: InputEvent) {
    return new Promise<void>((resolve) => {
      // Clear the previous timeout if it exists
      if (this.#debounceTimeout) {
        clearTimeout(this.#debounceTimeout);
      }
      // Set a new timeout
      this.#debounceTimeout = setTimeout(
        () => {
          this.dispatchEvent(
            new CustomEvent('search', {
              detail: this.input.value,
              bubbles: true,
              composed: true,
            })
          );
          resolve();
        },
        // Don't debounce the first input
        // Don't debounce in test mode
        this.#firstInput || import.meta.env.MODE === 'test' ? 0 : 350
      ); // debounce delay
      this.#firstInput = false;
    });
  }

  @property({type: String, reflect: true})
  public searchValue = '';

  @property({type: String})
  placeholder = 'Search';

  public async focusInput() {
    await this.updateComplete;

    this.input.focus();
    const length = this.input.value.length;
    this.input.setSelectionRange(length, length);
  }

  protected override render() {
    return html`<input type="search" role="search" 
    placeholder=${this.placeholder} 
    @input=${this.#changeHandler} .value=${this.searchValue}></input>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-search-box': SearchBox;
  }
}
