import '@/components/search/wf-search-panel';
import type {SearchBox} from '@/components/inputs/wf-search-box';

import {faLocationDot, faCircle} from '@fortawesome/pro-solid-svg-icons';
import {faLocationDot as faLocationDotOutline} from '@fortawesome/pro-regular-svg-icons';

@customElement('wf-route-search')
export class RouteSearch extends LitElement {
  static override styles = [
    fontAwesomeStyles,
    flexStyles,
    displayStyles,
    css`
      :host {
        display: flex;
        flex-direction: row;
        border: 1px solid var(--acq-panel-fg);
        border-radius: 5px;
      }
      .prepend {
        /* Style */
        background-color: var(--acq-panel-fg);
        color: var(--acq-panel-bg);
        width: 7rem;

        /* Typography */
        text-transform: uppercase;
        font-family: var(--acq-font-family-headings);
        font-size: 0.75rem; /* 12px */
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 18px */

        .fa-layers {
          width: 15px;
          height: 15px;
          margin: 10px;
        }
      }

      wf-search-box {
        width: 100%;
      }
    `,
  ];

  @property({type: String, reflect: true})
  searchValue = '';

  @property({type: String})
  direction: 'to' | 'from' = 'to';

  get #toIcon() {
    return layer((push) => {
      push(
        icon(faLocationDot, {
          styles: {
            color: '#EB5757',
          },
        })
      );
      push(icon(faLocationDotOutline));
    });
  }
  get #fromIcon() {
    return layer((push) => {
      push(icon(faCircle));
      push(
        icon(faCircle, {
          transform: {
            size: 13,
          },
          styles: {
            color: '#6FCF97',
          },
        })
      );
    });
  }

  @query('wf-search-box')
  searchBox!: SearchBox;

  public focusInput(): void {
    this.searchBox.focusInput();
  }

  protected override render(): unknown {
    return html`<div class="prepend flex align-center">
        ${when(
          this.direction === 'to',
          () => useLayer(this.#toIcon),
          () => useLayer(this.#fromIcon)
        )}
        ${this.direction}
      </div>
      <wf-search-box searchValue=${this.searchValue}></wf-search-box>`;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    'wf-route-search': RouteSearch;
  }
}
