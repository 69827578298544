@customElement('wf-button')
export class BaseButton extends LitElement {
  static override styles = [
    typographyStyles,
    css`
      :host {
        /* Typography */
        font-size: 0.75rem; /* 12px */
        line-height: 1.125rem; /* 18px */

        /* Style */
        color: var(--acq-button-fg);
        background: var(--acq-button-bg);
        transition: background 300ms ease-out;
        border: none;
        border-radius: 5px;
        padding: 1rem 0.625rem;
        cursor: pointer;
        user-select: none;

        /* Layout */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      :host(:not(:first-of-type):not(:last-of-type)) {
        border-radius: 0;
      }

      /* Nested */
      :host(.nested) {
        border-radius: 0;
        background-color: color-mix(in srgb, var(--acq-button-bg), white 25%);
        height: 32px;
        padding: 7px 10px 7px 15px;
      }

      /* Hover Styles */
      /* https://stackoverflow.com/a/28058919/2527692 */
      @media (hover: hover) {
        :host(:hover),
        :host(.nested:hover) {
          color: var(--acq-button-hover-fg);
          background: var(--acq-button-hover-bg);
        }
      }

      /* Active Styles */
      :host(:active),
      :host([active]),
      :host(.nested:active),
      :host(.nested[active]) {
        color: var(--acq-button-active-fg);
        background: var(--acq-button-active-bg);
      }
    `,
  ];

  override connectedCallback(): void {
    super.connectedCallback();
    this.role = 'button';
  }

  @property({type: Boolean})
  active = false;

  override render() {
    return html`<slot></slot>`;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    'wf-button': BaseButton;
  }
}
