import {faCircleExclamation} from '@fortawesome/pro-solid-svg-icons';

import '@/components/misc/wf-slim-heading';
import '@/components/wf-location-listing';
import '@/components/search/wf-search-suggestions';
import {WithCurrentFloor} from '@/mixins/CurrentFloor';

@customElement('wf-search-results')
export class SearchResults extends WithCurrentFloor {
  static override styles = [typographyStyles, fontAwesomeStyles, listingStyles];

  @property({type: String, reflect: true})
  searching!: 'to' | 'from' | undefined;

  @property({type: Boolean})
  isRouting = false;

  get #searchQuery() {
    return this.#search.store.searchValue;
  }

  #searchResultClicked({discriminator, ...destination}: SearchResult) {
    this.#previous.store.setPrevious('search', this.#searchQuery!);

    if (this.isRouting) {
      if (this.searching === 'to') {
        this.#routing.store.setTo(destination);
      } else if (this.searching === 'from') {
        this.#routing.store.setFrom(destination);
      }
      this.searching = undefined;
    } else {
      this.#selected.store.selectDestination(destination, discriminator);

      // Show the floor of the destination
      this.wayfinder.showFloor(destination.floor);

      // Zoom to destination
      const {behaviour} = this.#settings.store;
      if (behaviour.zoomToDestination) {
        if (discriminator === 'destination') {
          zoomToDestination(this.wayfinder, destination as WF.Destination);
        } else {
          zoomToAmenity(this.wayfinder, destination as WF.Amenity);
        }
      }
    }
  }

  #search = new StoreController(this, searchStore);
  #routing = new StoreController(this, routingStore);
  #previous = new StoreController(this, returnStore);
  #selected = new StoreController(this, destinationStore);
  #settings = new StoreController(this, settingsStore);

  // Get the correct component for displaying a single destination or amenity
  #singleDestinationAmenityHTML(result: SearchResult) {
    return choose(result.discriminator, [
      [
        'destination',
        () =>
          html`<wf-location-listing
            .destination=${result as WF.Destination}
            @click=${() => this.#searchResultClicked(result)}
          ></wf-location-listing>`,
      ],
      [
        'amenity',
        () =>
          html`<wf-location-listing
            .amenity=${result as WF.Amenity}
            @click=${() => this.#searchResultClicked(result)}
          ></wf-location-listing>`,
      ],
    ]);
  }

  /**
   * Get the search results
   * If we're searching 'to', remove the 'from' result
   * If we're searching 'from', remove the 'to' result
   */
  get #searchResults() {
    const results = search(this.wayfinder, this.#searchQuery);
    if (!this.isRouting) return results;

    const {to, from} = this.#routing.store;
    return results.filter((r) => {
      if (this.searching === 'to' && from?.id === r.id) return false;
      if (this.searching === 'from' && to?.id === r.id) return false;
      return true;
    });
  }

  // Get search results for the same floor
  get #searchResultsSameFloor(): SearchResult[] {
    return this.#searchResults.filter((r) => r.floor === this.currentFloorId);
  }

  // Get search results for other floors
  get #searchResultsOtherFloors(): SearchResult[] {
    return this.#searchResults.filter((r) => r.floor !== this.currentFloorId);
  }

  protected override render(): unknown {
    // No search query
    if (!this.#searchQuery && (this.isRouting || !this.searching))
      return html`<wf-search-suggestions
        ?isRouting=${this.isRouting}
        .isSearching=${this.searching}
      ></wf-search-suggestions>`;

    // Search query with no results
    if (
      !this.#searchResultsSameFloor.length &&
      !this.#searchResultsOtherFloors.length
    )
      return html`<p>${useIcon(faCircleExclamation)} No results found</p>`;

    // Search query with results
    const sameFloor = html`<wf-slim-heading>
        Nearby Destinations
      </wf-slim-heading>
      ${when(
        this.#searchResultsSameFloor.length > 0,
        () =>
          repeat(
            this.#searchResultsSameFloor,
            ({id}) => id,
            (destination) => this.#singleDestinationAmenityHTML(destination)
          ),
        () => html`<p>No nearby results</p>`
      )}`;

    const otherFloors = when(
      this.#searchResultsOtherFloors.length > 0,
      () =>
        html`<wf-slim-heading>Other Destinations</wf-slim-heading> ${repeat(
            this.#searchResultsOtherFloors,
            ({id}) => id,
            (destination) => this.#singleDestinationAmenityHTML(destination)
          )}`
    );

    return html`${sameFloor}${otherFloors}`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'wf-search-results': SearchResults;
  }
}
