import {Exome} from 'exome';
class ReturnStore extends Exome {
  public previous: 'search' | 'categories' | 'amenities' | string = '';
  public data:
    | WF.DestinationCategory
    | WF.AmenityType
    | WF.Destination
    | WF.Destination[]
    | WF.Amenity
    | string
    | null = null;

  public setPrevious(
    previous: typeof this.previous,
    data: Exclude<typeof this.data, null>
  ) {
    this.previous = previous;
    this.data = data;
  }

  public clearPrevious() {
    this.previous = '';
    this.data = null;
  }
}

export const returnStore = new ReturnStore();
