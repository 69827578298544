/** For lists inside panels, e.g. Destinations / Amenities */
export const listingStyles = css`
  /* 'Empty' text */
  p {
    color: var(--acq-text-muted-color);
    padding: 10px 20px;
    margin: 0;
  }

  wf-destination-list-header {
    border-bottom: 1px solid
      color-mix(in lch, var(--acq-panel-fg) 15%, var(--acq-panel-bg));
  }

  wf-destination-single,
  wf-category-single,
  wf-location-listing {
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    user-select: none; /* Standard syntax */
    &:not(:last-child) {
      border-bottom: 1px solid
        color-mix(in lch, var(--acq-panel-fg) 15%, var(--acq-panel-bg));
    }
  }
`;

export const cardStyles = css`
  .card {
    /* Style */
    display: block;
    border-radius: 5px;
    border: 1px solid var(--acq-panel-fg, black);
    background: var(--acq-panel-bg, white);
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);
    margin: 0 0.625rem auto;

    /* Sizing */
    box-sizing: border-box;
    min-width: 350px;
    width: calc(100% - calc(var(--acq-ui-padding) * 2));
    max-width: ${isMobile() ? 1920 : 350}px;
    min-height: calc(50px + 0.313rem);

    .scrollable {
      max-height: var(--acq-ui-panel-max-height, 100%);
    }
  }
`;
